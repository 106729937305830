import React from 'react'
import { VisitPoint } from '../act-1'
const catalog = require('./../../../assets/mock-data/catalog.json')

const ActMap = ({ height, width, setActiveProduct, hoverProduct, history = {} }) => {
    const [hovered, setHover] = React.useState("")
    const findProduct = (id) => catalog.find(x => x.id === id)
    const clickIn = (id) => { hoverProduct && setHover(); if (history?.push && id?.startsWith('visit-')) history.push(`/vr/${id.replace('visit-', '', 1)}`) }
    const hoverIn = (id) => { if (hovered === id) return; hoverProduct && setHover(id); setActiveProduct && setActiveProduct(findProduct(id)) }

    return (
        <svg width={width} height={height} viewBox="-15 0 287 127" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M94.662 123.402H73.4894V108.166" stroke="#545454" strokeWidth="7" />
            <path d="M73.59 80.3548V45.6135H-30V4L249 4L249 45.6492L145.404 45.6492V123.401H126.411" stroke="#545454" strokeWidth="7" />
            <VisitPoint hovered={hovered} clickIn={clickIn} hoverIn={hoverIn} url="visit-entrance/1"><path d="M84.9568 92.2365C83.3043 92.2365 81.9648 93.576 81.9648 95.2285C81.9648 96.8809 83.3043 98.2205 84.9568 98.2205C86.6092 98.2205 87.9488 96.8809 87.9488 95.2285C87.9488 93.576 86.6092 92.2365 84.9568 92.2365Z" fill="#339966" /></VisitPoint>
            <VisitPoint hovered={hovered} clickIn={clickIn} hoverIn={hoverIn} url="visit-entrance/3"><path d="M110.938 53.1825C108.183 53.1825 105.951 55.4151 105.951 58.1692C105.951 60.9233 108.183 63.1559 110.938 63.1559C113.692 63.1559 115.924 60.9233 115.924 58.1692C115.924 55.4151 113.692 53.1825 110.938 53.1825Z" fill="#339966" /></VisitPoint>
        </svg>
    )
}
export const ActMap00 = React.memo(ActMap)
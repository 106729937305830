import React from 'react'
import { isMobile } from '../../utils'
import styled from 'styled-components'
const StyledDiv = styled.div`
.cloudimage-inner-box{
  height: ${p=> p.height}px!important;
  max-height: 600px;
  width:auto;
  canvas{
    height: 100% !important; 
    margin-left: auto; 
    margin-right: auto
  }

  @media (max-width: 768px){
    height: 350px !important;
    canvas{
      height: 280px !important;
    }
  }
}`

const ProductCI360Component = React.memo(({ product }) => {
  const [height, setHeight] = React.useState(300)
  const updateMaxHeight = (r) => { r && setHeight(r.parentElement.clientHeight - 100) }
  React.useEffect(() => window.CI360.init(), [product])
  const className = `product-360 d-flex flex-column w-100 cloudimage-360 ${isMobile() ? 'canvas-100' : ''}`
  if (!product) return <div />
  return (
    <StyledDiv
      height={height}
      ref={updateMaxHeight}
      className={className}
      data-folder="/products/"
      data-filename={`${product.id}_{index}.png`}
      data-amount={Number(product.amount)}
      data-hide-360-logo
    >
      <button className="cloudimage-360-prev"></button>
      <button className="cloudimage-360-next"></button>
    </StyledDiv>
  )
})

export default ProductCI360Component
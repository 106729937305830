import React from 'react'
import { ViewProduct, VisitPoint } from '../act-1'
const catalog = require('./../../../assets/mock-data/catalog.json')


const ActMap = ({ height, width, setActiveProduct, hoverProduct, history = {} }) => {
  const [hovered, setHover] = React.useState("")
  const findProduct = (id) => catalog.find(x => x.id === id)
  const clickIn = (id) => { hoverProduct && setHover(); if (history?.push && id?.startsWith('visit-')) history.push(`/vr/${id.replace('visit-', '', 1)}`) }
  const hoverIn = (id) => { if (hovered === id) return; hoverProduct && setHover(id); setActiveProduct && setActiveProduct(findProduct(id)) }

  return (
    <svg width={width} height={height} viewBox="0 0 184 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="no-touch" d="M90.2442 148.654H149.369V166.494H110.576L109.538 245.795H90.2442V148.654Z" fill="white" fillOpacity="0.1" stroke="#545454" />
      <ViewProduct hovered={hovered} hoverIn={hoverIn} name="act5-loren-bar-chair">
        <path d="M87.6435 229.14L83.2154 229.337C82.1762 229.385 81.1369 229.256 80.156 228.961C79.1749 228.665 78.2718 228.209 77.4984 227.616C76.725 227.024 76.0962 226.307 75.6481 225.507C75.2 224.707 74.9413 223.841 74.8868 222.956C74.8322 222.071 74.983 221.187 75.3304 220.351C75.6777 219.516 76.2151 218.747 76.9116 218.089C77.6081 217.43 78.4501 216.895 79.3895 216.515C80.329 216.133 81.3473 215.914 82.3864 215.867L86.8145 215.67C86.9775 215.663 87.1416 215.683 87.2971 215.729C87.4515 215.776 87.5941 215.849 87.7164 215.942C87.8386 216.035 87.9373 216.148 88.0081 216.275C88.0789 216.401 88.1196 216.538 88.1282 216.677L88.8264 228.021C88.8435 228.303 88.7288 228.579 88.5068 228.789C88.2848 228.998 87.9748 229.125 87.6435 229.14Z" stroke="#545454" />
        <path d="M68.7635 186.298L72.603 184.411C73.5039 183.968 74.4986 183.68 75.5301 183.563C76.5617 183.447 77.61 183.506 78.6152 183.735C79.6204 183.964 80.5627 184.359 81.3883 184.899C82.2138 185.438 82.9066 186.111 83.4267 186.878C83.9469 187.645 84.2847 188.492 84.4209 189.37C84.5571 190.249 84.4885 191.141 84.2182 191.997C83.949 192.852 83.4836 193.655 82.8498 194.357C82.216 195.06 81.426 195.649 80.5245 196.092L76.685 197.979C76.5428 198.049 76.3858 198.094 76.223 198.112C76.0602 198.131 75.8948 198.122 75.7361 198.086C75.5775 198.049 75.4289 197.987 75.2986 197.902C75.1683 197.817 75.059 197.711 74.9769 197.589L68.3058 187.752C68.14 187.508 68.0951 187.217 68.1809 186.944C68.2668 186.672 68.4763 186.439 68.7635 186.298Z" stroke="#545454" />
        <path d="M87.0977 170.243L82.6643 170.177C81.624 170.163 80.5974 169.973 79.6429 169.62C78.6886 169.267 77.8252 168.758 77.1021 168.12C76.3791 167.484 75.8106 166.732 75.429 165.907C75.0476 165.083 74.8605 164.203 74.8788 163.317C74.8968 162.431 75.1196 161.557 75.5346 160.745C75.9496 159.932 76.5485 159.197 77.2973 158.582C78.046 157.966 78.9298 157.483 79.8982 157.158C80.8666 156.834 81.9007 156.675 82.941 156.691L87.3733 156.758C87.5385 156.759 87.7004 156.79 87.8506 156.845C88.0007 156.901 88.1369 156.981 88.2516 157.082C88.3653 157.183 88.4554 157.302 88.5155 157.431C88.5755 157.562 88.6055 157.701 88.6023 157.84L88.3696 169.198C88.3632 169.48 88.2259 169.748 87.9878 169.945C87.7487 170.141 87.4291 170.249 87.0977 170.243Z" stroke="#545454" />
        <path d="M90.3492 141.484L92.2228 138.064C92.6614 137.261 93.2823 136.539 94.0491 135.941C94.8159 135.342 95.7146 134.878 96.6916 134.574C97.6696 134.271 98.7077 134.135 99.748 134.173C100.787 134.212 101.808 134.424 102.751 134.798C103.693 135.173 104.542 135.702 105.244 136.355C105.948 137.009 106.493 137.773 106.847 138.606C107.204 139.438 107.363 140.322 107.317 141.208C107.272 142.093 107.021 142.962 106.582 143.764L104.708 147.185C104.638 147.311 104.54 147.426 104.419 147.52C104.298 147.615 104.156 147.688 104.002 147.735C103.848 147.783 103.684 147.804 103.52 147.799C103.356 147.793 103.195 147.759 103.046 147.7L90.9541 142.9C90.6527 142.78 90.4211 142.564 90.3074 142.299C90.1937 142.033 90.2087 141.74 90.3492 141.484Z" stroke="#545454" />
        <path d="M129.392 146.525L129.315 142.751C129.295 141.865 129.482 140.985 129.864 140.161C130.245 139.336 130.812 138.584 131.535 137.946C132.258 137.309 133.121 136.8 134.075 136.446C135.029 136.093 136.056 135.903 137.096 135.888C138.137 135.872 139.17 136.031 140.139 136.356C141.107 136.68 141.991 137.164 142.739 137.78C143.487 138.396 144.086 139.131 144.5 139.943C144.915 140.756 145.137 141.63 145.156 142.516L145.233 146.29C145.235 146.429 145.206 146.568 145.146 146.699C145.086 146.828 144.996 146.947 144.882 147.048C144.767 147.148 144.631 147.229 144.481 147.285C144.33 147.341 144.168 147.371 144.004 147.373L130.664 147.571C130.333 147.576 130.012 147.468 129.774 147.272C129.535 147.076 129.397 146.807 129.392 146.525Z" stroke="#545454" />
      </ViewProduct>
      <ViewProduct hovered={hovered} hoverIn={hoverIn} name="act5-binoche-chair">
        <path d="M81.6776 109.922L80.3072 113.511C79.9862 114.354 79.4734 115.134 78.798 115.808C78.1226 116.482 77.2978 117.036 76.3708 117.438C75.4439 117.841 74.4329 118.084 73.3955 118.154C72.3583 118.223 71.3151 118.118 70.3255 117.844C69.3358 117.57 68.4191 117.134 67.6279 116.558C66.8366 115.983 66.1863 115.281 65.7141 114.491C65.2418 113.701 64.957 112.841 64.8757 111.957C64.7945 111.074 64.9185 110.186 65.2406 109.343L66.6105 105.754C66.6612 105.621 66.7422 105.497 66.8489 105.391C66.9555 105.286 67.0857 105.198 67.2319 105.134C67.3783 105.071 67.5378 105.033 67.7016 105.022C67.8652 105.011 68.0298 105.027 68.1861 105.07L80.8743 108.581C81.1897 108.667 81.4515 108.858 81.6021 109.109C81.7526 109.361 81.7794 109.654 81.6776 109.922Z" stroke="#545454" />
        <path d="M62.9736 79.8896L62.9246 77.5011C62.8896 75.7937 63.6526 74.1448 65.0457 72.9159C66.4389 71.6878 68.348 70.9812 70.3531 70.9519C72.5491 70.9191 74.6705 71.6303 76.2504 72.9296C77.8303 74.2279 78.7394 76.0083 78.7777 77.8782L78.8142 79.654C78.8216 80.0165 78.7451 80.3762 78.5892 80.7141C78.4331 81.051 78.2007 81.3587 77.9051 81.6189C77.6094 81.88 77.2563 82.0882 76.8661 82.2324C76.4757 82.3776 76.0559 82.4552 75.6303 82.4616L66.2705 82.6004C65.4111 82.6132 64.581 82.3347 63.9626 81.8261C63.3444 81.3185 62.9885 80.6219 62.9736 79.8896Z" stroke="#545454" />
      </ViewProduct>
      <ViewProduct hovered={hovered} hoverIn={hoverIn} name="act5-stranger-chair">
        <path d="M155.127 106.637L155.05 110.41C155.033 111.296 154.81 112.171 154.396 112.983C153.981 113.796 153.382 114.531 152.634 115.146C151.886 115.762 151.003 116.246 150.034 116.571C149.066 116.896 148.032 117.054 146.992 117.039C145.952 117.023 144.925 116.834 143.971 116.481C143.016 116.128 142.153 115.617 141.43 114.98C140.707 114.343 140.139 113.59 139.758 112.766C139.378 111.942 139.191 111.061 139.209 110.175L139.286 106.401C139.29 106.262 139.325 106.124 139.39 105.995C139.456 105.867 139.55 105.751 139.668 105.654C139.786 105.557 139.926 105.481 140.079 105.429C140.231 105.378 140.395 105.353 140.559 105.356L153.899 105.554C154.231 105.559 154.546 105.675 154.777 105.878C155.007 106.082 155.134 106.355 155.127 106.637Z" stroke="#545454" />
        <path d="M118.709 108.331L118.659 110.72C118.625 112.427 117.795 114.052 116.352 115.238C114.909 116.425 112.972 117.075 110.967 117.044C108.771 117.012 106.68 116.238 105.154 114.892C103.629 113.548 102.794 111.742 102.831 109.872L102.868 108.096C102.875 107.733 102.966 107.375 103.137 107.043C103.306 106.711 103.551 106.41 103.858 106.159C104.163 105.907 104.525 105.709 104.92 105.577C105.317 105.443 105.74 105.379 106.165 105.385L115.525 105.524C116.385 105.537 117.203 105.84 117.799 106.367C118.397 106.892 118.724 107.599 118.709 108.331Z" stroke="#545454" />
        <path d="M138.856 78.7383L139.582 76.4301C140.102 74.7812 141.37 73.3751 143.107 72.5214C144.845 71.6678 146.909 71.4368 148.846 71.8787C150.967 72.3626 152.776 73.545 153.875 75.1647C154.973 76.7844 155.27 78.7091 154.7 80.515L154.16 82.2315C154.05 82.5821 153.86 82.9099 153.601 83.1975C153.342 83.4851 153.019 83.7262 152.65 83.9069C152.281 84.0886 151.875 84.2055 151.452 84.253C151.031 84.3005 150.602 84.2767 150.19 84.1827L141.147 82.1183C140.317 81.9284 139.609 81.4664 139.179 80.8328C138.75 80.1982 138.633 79.445 138.856 78.7383Z" stroke="#545454" />
        <path d="M102.804 81.4673V77.6928C102.803 76.8063 103.008 75.9289 103.406 75.1108C103.804 74.2918 104.387 73.5486 105.123 72.9213C105.859 72.295 106.732 71.7983 107.693 71.4587C108.655 71.1199 109.685 70.9455 110.725 70.9455C111.766 70.9446 112.796 71.1199 113.758 71.4587C114.719 71.7974 115.593 72.295 116.329 72.9213C117.065 73.5477 117.648 74.2918 118.046 75.1108C118.444 75.9289 118.647 76.8063 118.647 77.6928V81.4673C118.647 81.607 118.615 81.7449 118.552 81.8745C118.49 82.0033 118.398 82.121 118.281 82.2196C118.165 82.3182 118.028 82.3968 117.875 82.4506C117.724 82.5036 117.561 82.5319 117.397 82.5319H104.055C103.724 82.5319 103.405 82.4196 103.17 82.2196C102.936 82.0197 102.804 81.7494 102.804 81.4673Z" stroke="#545454" />
      </ViewProduct>
      <ViewProduct hovered={hovered} hoverIn={hoverIn} name="act5-shinto-sofa">
        <path d="M61.0206 50.4654H119.459C122.124 50.4654 124.285 48.6256 124.285 46.3567V25.4447C124.285 23.1749 122.124 21.336 119.459 21.336H61.0206C58.3553 21.336 56.1947 23.1749 56.1947 25.4447V46.3567C56.1947 48.6256 58.3553 50.4654 61.0206 50.4654Z" stroke="#545454" />
        <path d="M95.1806 29.2356H119.646C122.208 29.2356 124.285 27.467 124.285 25.2858C124.285 23.1037 122.208 21.336 119.646 21.336H95.1806C92.6186 21.336 90.5413 23.1037 90.5413 25.2858C90.5413 27.467 92.6186 29.2356 95.1806 29.2356Z" stroke="#545454" />
        <path d="M56.1947 46.7155C56.1947 48.7863 58.1664 50.4654 60.5986 50.4654C63.0308 50.4654 65.0026 48.7863 65.0026 46.7155V25.0858C65.0026 23.0142 63.0308 21.336 60.5986 21.336C58.1664 21.336 56.1947 23.0142 56.1947 25.0858V46.7155Z" stroke="#545454" />
      </ViewProduct>
      <ViewProduct hovered={hovered} hoverIn={hoverIn} name="act4-panton-side-table">
        <path d="M78.7124 20.423H78.7135C82.8349 20.423 86.1755 17.5789 86.1755 14.0701V14.0692C86.1755 10.5605 82.8349 7.71637 78.7135 7.71637H78.7124C74.5913 7.71637 71.2505 10.5605 71.2505 14.0692V14.0701C71.2505 17.5789 74.5913 20.423 78.7124 20.423Z" stroke="#545454" />
      </ViewProduct>

      <path className="no-touch" d="M99.8842 104.999H122.468V85.3714H99.8842V104.999Z" fill="white" fillOpacity="0.1" />
      <path className="no-touch" d="M100.42 104.542H121.932V85.828H100.42V104.542Z" stroke="#545454" />
      <path className="no-touch" d="M136.112 104.999H158.696V85.3714H136.112V104.999Z" fill="white" fillOpacity="0.1" />
      <path className="no-touch" d="M136.648 104.542H158.16V85.828H136.648V104.542Z" stroke="#545454" />
      <path className="no-touch" d="M62.2454 104.999H84.8295V85.3714H62.2454V104.999Z" fill="white" fillOpacity="0.1" />
      <path className="no-touch" d="M62.7816 104.542H84.2933V85.828H62.7816V104.542Z" stroke="#545454" />
      <path className="no-touch" d="M124.768 -89.1432V4.89874H42.2288" stroke="#8C8C8C" strokeWidth="3" />
      <path className="no-touch" d="M-18 -89.145H166.887L167 246H-18" stroke="#545454" strokeWidth="7" />
      <path className="no-touch" d="M42.1554 245.795V-88.3871" stroke="#545454" strokeWidth="7" />


      <VisitPoint hovered={hovered} clickIn={clickIn} hoverIn={hoverIn} url="visit-zone-5/1"><path d="M56.4783 171.288C53.813 171.288 51.6523 173.126 51.6523 175.396C51.6523 177.665 53.813 179.505 56.4783 179.505C59.1436 179.505 61.3042 177.665 61.3042 175.396C61.3042 173.126 59.1436 171.288 56.4783 171.288Z" fill="#339966" /></VisitPoint>
      <VisitPoint hovered={hovered} clickIn={clickIn} hoverIn={hoverIn} url="visit-zone-5/2"><path d="M79.0621 130.43C76.3968 130.43 74.2362 132.268 74.2362 134.538C74.2362 136.807 76.3968 138.647 79.0621 138.647C81.7269 138.647 83.8878 136.807 83.8878 134.538C83.8878 132.268 81.7269 130.43 79.0621 130.43Z" fill="#339966" /></VisitPoint>
      <VisitPoint hovered={hovered} clickIn={clickIn} hoverIn={hoverIn} url="visit-zone-5/3"><path d="M94.5886 66.3384C91.9225 66.3384 89.7626 68.1781 89.7626 70.447C89.7626 72.7168 91.9225 74.5557 94.5886 74.5557C97.2536 74.5557 99.4145 72.7168 99.4145 70.447C99.4145 68.1781 97.2536 66.3384 94.5886 66.3384Z" fill="#339966" /></VisitPoint>
      <VisitPoint hovered={hovered} clickIn={clickIn} hoverIn={hoverIn} url="visit-zone-5/4"><path d="M140.226 47.9125C137.561 47.9125 135.4 49.7514 135.4 52.0212C135.4 54.29 137.561 56.1298 140.226 56.1298C142.892 56.1298 145.052 54.29 145.052 52.0212C145.052 49.7514 142.892 47.9125 140.226 47.9125Z" fill="#339966" /></VisitPoint>
    </svg>
  )
}

export const ActMap5 = React.memo(ActMap)
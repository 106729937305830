import React from 'react'
import { Tooltip } from 'antd'

const ActMap = React.memo(({ height, width, history = {} }) => {
    const [hovered, setHover] = React.useState("catalog")
    const opacityClass = (id) => (hovered.includes(id) ? 1 : 0.25)
    const isHovered = (id) => (hovered === id)
    const clickIn = (id) => { setHover(); if (history?.push && id?.startsWith('visit-')) history.push(`/vr/${id.replace('visit-', '', 1)}`) }
    const hoverIn = (id) => { if (hovered === id) return; setHover(id) }

    return (
        <svg width={width} height={height} viewBox="0 0 750 570" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Tooltip visible={hovered.includes('zone-production')} title="Production">
                <g id="zone-production" opacity={opacityClass("zone-production")} onClick={() => hoverIn("zone-production")} onPointerOver={() => hoverIn("zone-production")} onPointerEnter={() => hoverIn("zone-production")}>
                    <path id="Vector" d="M192.656 277.845H222.489V229.147H192.656V277.845Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_2" d="M193.156 277.345H221.989V229.647H193.156V277.345Z" stroke="#545454" />
                    <path id="Path 726" d="M226.137 369.508H257.077V187.607H312.177V165.043" stroke="#545454" strokeWidth="7" />
                    <path id="Path 727" d="M195.751 369.508H156.524L156.524 70.6074H312.324V122.87" stroke="#545454" strokeWidth="7" />
                    <g onClick={() => clickIn("visit-production/1")} onPointerOver={() => hoverIn("visit-production/1")} onPointerEnter={() => hoverIn("visit-production/1")}><Tooltip trigger={["hover", "click", isHovered("visit-production/1")]} title="VISIT"><path id="Ellipse 218" d="M208.776 305.258C206.291 305.258 204.276 307.273 204.276 309.758C204.276 312.243 206.291 314.258 208.776 314.258C211.262 314.258 213.276 312.243 213.276 309.758C213.276 307.273 211.262 305.258 208.776 305.258Z" fill="#339966" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-production/2")} onPointerOver={() => hoverIn("visit-production/2")} onPointerEnter={() => hoverIn("visit-production/2")}><Tooltip trigger={["hover", "click", isHovered("visit-production/2")]} title="VISIT"><path id="Ellipse 219" d="M239.047 246.47C236.562 246.47 234.547 248.484 234.547 250.97C234.547 253.455 236.562 255.47 239.047 255.47C241.532 255.47 243.547 253.455 243.547 250.97C243.547 248.484 241.532 246.47 239.047 246.47Z" fill="#339966" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-production/3")} onPointerOver={() => hoverIn("visit-production/3")} onPointerEnter={() => hoverIn("visit-production/3")}><Tooltip trigger={["hover", "click", isHovered("visit-production/3")]} title="VISIT"><path id="Ellipse 220" d="M211.408 193.385C208.923 193.385 206.908 195.399 206.908 197.885C206.908 200.37 208.923 202.385 211.408 202.385C213.893 202.385 215.908 200.37 215.908 197.885C215.908 195.399 213.893 193.385 211.408 193.385Z" fill="#339966" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-production/4")} onPointerOver={() => hoverIn("visit-production/4")} onPointerEnter={() => hoverIn("visit-production/4")}><Tooltip trigger={["hover", "click", isHovered("visit-production/4")]} title="VISIT"><path id="Ellipse 223" d="M212.286 141.614C209.801 141.614 207.786 143.629 207.786 146.114C207.786 148.6 209.801 150.614 212.286 150.614C214.771 150.614 216.786 148.6 216.786 146.114C216.786 143.629 214.771 141.614 212.286 141.614Z" fill="#339966" /></Tooltip></g>
                </g>
            </Tooltip>

            <Tooltip visible={hovered.includes('zone-entrance')} title="Entrance">
                <g id="zone-entrance" opacity={opacityClass("zone-entrance")} onClick={() => hoverIn("zone-entrance")} onPointerOver={() => hoverIn("zone-entrance")} onPointerEnter={() => hoverIn("zone-entrance")}>
                    <path id="Path 730" d="M344.021 187.61H312.178V164.695" stroke="#545454" strokeWidth="7" />
                    <path id="Path 729" d="M312.329 122.867V70.6162L156.529 70.6162V8.0293L576.146 8.0293V70.6699L420.338 70.6699V187.609H391.772" stroke="#545454" strokeWidth="7" />
                    <g onClick={() => clickIn("visit-entrance/1")} onPointerOver={() => hoverIn("visit-entrance/1")} onPointerEnter={() => hoverIn("visit-entrance/1")}><Tooltip trigger={["hover", "click", isHovered("visit-entrance/1")]} title="VISIT"><path id="Ellipse 224" d="M329.425 140.737C326.94 140.737 324.925 142.752 324.925 145.237C324.925 147.723 326.94 149.737 329.425 149.737C331.91 149.737 333.925 147.723 333.925 145.237C333.925 142.752 331.91 140.737 329.425 140.737Z" fill="#339966" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-entrance/2")} onPointerOver={() => hoverIn("visit-entrance/2")} onPointerEnter={() => hoverIn("visit-entrance/2")}><Tooltip trigger={["hover", "click", isHovered("visit-entrance/2")]} title="VISIT"><path id="Ellipse 225" d="M369.348 134.105C366.862 134.105 364.848 136.12 364.848 138.605C364.848 141.091 366.862 143.105 369.348 143.105C371.833 143.105 373.848 141.091 373.848 138.605C373.848 136.12 371.833 134.105 369.348 134.105Z" fill="#339966" /></Tooltip></g>
                    <g onClick={() => clickIn("visit-entrance/3")} onPointerOver={() => hoverIn("visit-entrance/3")} onPointerEnter={() => hoverIn("visit-entrance/3")}><Tooltip trigger={["hover", "click", isHovered("visit-entrance/3")]} title="VISIT"><path id="Ellipse 232" d="M368.5 82C364.358 82 361 85.3579 361 89.5C361 93.6421 364.358 97 368.5 97C372.642 97 376 93.6421 376 89.5C376 85.3579 372.642 82 368.5 82Z" fill="#339966" /></Tooltip></g>
                </g>
            </Tooltip>

            <Tooltip visible={hovered.includes('zone-1')} title="Zone 1">
                <g id="zone-1" opacity={opacityClass("zone-1")} onClick={() => hoverIn("zone-1")} onPointerOver={() => hoverIn("zone-1")} onPointerEnter={() => hoverIn("zone-1")}>
                    <path id="Path 728_2" d="M430.539 314.283H303.796" stroke="#545454" strokeWidth="7" />
                    <path id="Path 715" d="M345.036 299.192V243.618H313.348V299.192H345.036Z" stroke="#545454" />
                    <path id="Path 716" d="M376.581 299.192V267.278H344.893V299.192H376.581Z" fill="white" fillOpacity="0.1" stroke="#545454" />
                    <path id="Path 717" d="M414.235 299.192L421.235 282.592L414.235 267.274H376.436V299.189L414.235 299.192Z" stroke="#545454" />
                    <path id="Path 718" d="M321.841 291.356C321.841 291.356 321.865 277.151 321.841 274.452C321.787 273.279 321.401 272.146 320.727 271.185C320.052 270.224 319.118 269.477 318.034 269.028C316.502 268.577 314.9 268.415 313.31 268.552V299.192H344.918C344.793 297.739 344.456 296.313 343.918 294.957C343.335 293.842 342.452 292.913 341.366 292.277C340.281 291.64 339.038 291.321 337.78 291.357C333.989 291.317 321.841 291.356 321.841 291.356Z" stroke="#545454" />
                    <path id="Path 719" d="M408.676 291.731C408.676 291.731 385.206 291.694 382.507 291.718C381.335 291.772 380.202 292.158 379.241 292.832C378.28 293.507 377.531 294.441 377.082 295.525C376.642 296.752 376.48 298.06 376.607 299.357L414.223 299.365L420.923 283.247C419.231 282.696 417.45 282.468 415.674 282.575C414.516 282.74 413.424 283.218 412.516 283.956C411.609 284.695 410.92 285.667 410.523 286.768C409.662 288.926 408.676 291.731 408.676 291.731Z" stroke="#545454" />
                    <path id="Vector_27" d="M375.068 298.841H346.306C346.064 298.841 345.832 298.745 345.661 298.574C345.489 298.403 345.393 298.17 345.393 297.928V297.928C345.393 297.142 345.547 296.363 345.848 295.636C346.149 294.91 346.59 294.25 347.146 293.694C347.702 293.138 348.363 292.697 349.089 292.396C349.816 292.095 350.595 291.941 351.381 291.941H369.997C370.783 291.941 371.562 292.096 372.288 292.397C373.014 292.698 373.674 293.139 374.23 293.695C374.785 294.251 375.226 294.911 375.527 295.637C375.827 296.364 375.982 297.142 375.981 297.928V297.928C375.981 298.17 375.885 298.403 375.714 298.574C375.543 298.745 375.31 298.841 375.068 298.841V298.841Z" stroke="#545454" />
                    <path id="Vector_28" d="M421.106 226.076L413.969 237.063C413.673 237.52 413.059 237.65 412.602 237.353L412.565 237.329L412.525 237.312C412.483 237.295 408.278 235.495 404.368 232.955C400.451 230.411 396.722 227.055 396.685 227.021L396.655 226.994L396.622 226.973C396.165 226.676 396.035 226.063 396.332 225.605L403.468 214.619C405.911 210.858 410.959 209.785 414.721 212.229L418.716 214.824C422.477 217.267 423.549 222.315 421.106 226.076Z" fill="white" fillOpacity="0.05" />
                    <path id="Vector_29" d="M396.751 225.878C396.605 226.104 396.669 226.407 396.895 226.553L396.961 226.597L397.02 226.65C397.057 226.683 400.762 230.016 404.641 232.536C408.511 235.05 412.68 236.834 412.721 236.852L412.802 236.886L412.875 236.934C413.101 237.08 413.404 237.016 413.55 236.79L420.687 225.804C422.98 222.274 421.973 217.536 418.443 215.243L414.448 212.648C410.918 210.355 406.181 211.362 403.888 214.892L396.751 225.878ZM395.912 225.333L403.049 214.347C405.647 210.348 410.994 209.212 414.993 211.809L418.988 214.404C422.987 217.002 424.123 222.35 421.526 226.349L414.389 237.335C413.941 238.025 413.019 238.221 412.33 237.773C412.33 237.773 408.091 235.97 404.096 233.375C400.101 230.78 396.35 227.392 396.35 227.392C395.66 226.944 395.464 226.022 395.912 225.333Z" fill="#545454" />
                    <path id="Vector_30" d="M367.267 249.327H381.745C386.833 249.327 390.958 245.202 390.958 240.114C390.958 235.026 386.833 230.901 381.745 230.901H367.267C362.179 230.901 358.054 235.026 358.054 240.114C358.054 245.202 362.179 249.327 367.267 249.327Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_31" d="M367.267 248.827H381.745C386.557 248.827 390.458 244.926 390.458 240.114C390.458 235.302 386.557 231.401 381.745 231.401H367.267C362.455 231.401 358.554 235.302 358.554 240.114C358.554 244.926 362.455 248.827 367.267 248.827Z" stroke="#545454" />
                    <g className="pointer" onClick={() => clickIn("visit-zone-1/1")} onPointerOver={() => hoverIn("visit-zone-1/1")} onPointerEnter={() => hoverIn("visit-zone-1/1")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-1/1")]} title="VISIT"><path id="Ellipse 228" d="M447.44 299.116C444.955 299.116 442.94 301.131 442.94 303.616C442.94 306.101 444.955 308.116 447.44 308.116C449.926 308.116 451.94 306.101 451.94 303.616C451.94 301.131 449.926 299.116 447.44 299.116Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-1/2")} onPointerOver={() => hoverIn("visit-zone-1/2")} onPointerEnter={() => hoverIn("visit-zone-1/2")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-1/2")]} title="VISIT"><path id="Ellipse 214" d="M287.308 303.504C284.822 303.504 282.808 305.519 282.808 308.004C282.808 310.489 284.822 312.504 287.308 312.504C289.793 312.504 291.808 310.489 291.808 308.004C291.808 305.519 289.793 303.504 287.308 303.504Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-1/3")} onPointerOver={() => hoverIn("visit-zone-1/3")} onPointerEnter={() => hoverIn("visit-zone-1/3")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-1/3")]} title="VISIT"><path id="Ellipse 215" d="M295.643 248.225C293.157 248.225 291.143 250.239 291.143 252.725C291.143 255.21 293.157 257.225 295.643 257.225C298.128 257.225 300.143 255.21 300.143 252.725C300.143 250.239 298.128 248.225 295.643 248.225Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-1/4")} onPointerOver={() => hoverIn("visit-zone-1/4")} onPointerEnter={() => hoverIn("visit-zone-1/4")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-1/4")]} title="VISIT"><path id="Ellipse 231" d="M441.298 260.07C438.813 260.07 436.798 262.085 436.798 264.57C436.798 267.056 438.813 269.07 441.298 269.07C443.783 269.07 445.798 267.056 445.798 264.57C445.798 262.085 443.783 260.07 441.298 260.07Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-1/5")} onPointerOver={() => hoverIn("visit-zone-1/5")} onPointerEnter={() => hoverIn("visit-zone-1/5")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-1/5")]} title="VISIT"><path id="Ellipse 216" d="M332.495 214.882C330.01 214.882 327.995 216.897 327.995 219.382C327.995 221.867 330.01 223.882 332.495 223.882C334.98 223.882 336.995 221.867 336.995 219.382C336.995 216.897 334.98 214.882 332.495 214.882Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-1/6")} onPointerOver={() => hoverIn("visit-zone-1/6")} onPointerEnter={() => hoverIn("visit-zone-1/6")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-1/6")]} title="VISIT"><path id="Ellipse 217" d="M369.348 196.017C366.862 196.017 364.848 198.031 364.848 200.517C364.848 203.002 366.862 205.017 369.348 205.017C371.833 205.017 373.848 203.002 373.848 200.517C373.848 198.031 371.833 196.017 369.348 196.017Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-1/7")} onPointerOver={() => hoverIn("visit-zone-1/7")} onPointerEnter={() => hoverIn("visit-zone-1/7")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-1/7")]} title="VISIT"><path id="Ellipse 222" d="M404.445 242.082C401.96 242.082 399.945 244.097 399.945 246.582C399.945 249.067 401.96 251.082 404.445 251.082C406.931 251.082 408.945 249.067 408.945 246.582C408.945 244.097 406.931 242.082 404.445 242.082Z" fill="#339966" /></Tooltip></g>
                </g>
            </Tooltip>

            <Tooltip visible={hovered.includes('zone-2')} title="Zone 2">
                <g id="zone-2" opacity={opacityClass("zone-2")} onClick={() => hoverIn("zone-2")} onPointerOver={() => hoverIn("zone-2")} onPointerEnter={() => hoverIn("zone-2")}>
                    <path id="Vector_3" d="M292.607 446.422L298.369 442.68C303.466 439.369 304.914 432.553 301.603 427.456L299.806 424.69C296.495 419.593 289.679 418.145 284.582 421.456L278.821 425.199C273.724 428.51 272.276 435.326 275.587 440.423L277.383 443.188C280.694 448.285 287.51 449.733 292.607 446.422Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_4" d="M292.335 446.003L298.096 442.26C302.962 439.1 304.344 432.593 301.183 427.728L299.387 424.963C296.227 420.097 289.721 418.715 284.855 421.876L279.094 425.618C274.228 428.779 272.846 435.285 276.007 440.15L277.803 442.916C280.964 447.781 287.47 449.163 292.335 446.003Z" stroke="#545454" strokeWidth="1.00047" />

                    <path id="Vector_5" d="M395.857 423.561H402.732L406.178 429.642L402.732 435.723H395.857L392.411 429.642L395.857 423.561Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_6" d="M402.441 435.223L405.604 429.642L402.441 424.061H396.148L392.986 429.642L396.148 435.223H402.441ZM403.024 436.223H395.566L391.836 429.642L395.566 423.061H403.024L406.753 429.642L403.024 436.223Z" fill="#545454" />
                    <path id="Vector_7" d="M399.075 423.062C395.562 423.062 392.714 425.91 392.714 429.423C392.714 432.936 395.562 435.784 399.075 435.784C402.588 435.784 405.436 432.936 405.436 429.423C405.436 425.91 402.588 423.062 399.075 423.062Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_8" d="M399.075 423.562C395.838 423.562 393.214 426.186 393.214 429.423C393.214 432.66 395.838 435.284 399.075 435.284C402.312 435.284 404.936 432.66 404.936 429.423C404.936 426.186 402.312 423.562 399.075 423.562Z" stroke="#545454" />

                    <path id="Vector_9" d="M383.389 414.836L389.336 418.553L389.103 425.538L382.926 428.809L376.979 425.092L377.212 418.107L383.389 414.836Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_10" d="M382.947 428.232L388.613 425.232L388.827 418.824L383.369 415.413L377.703 418.413L377.489 424.821L382.947 428.232ZM382.905 429.385L376.47 425.363L376.722 417.8L383.41 414.259L389.845 418.281L389.593 425.844L382.905 429.385Z" fill="#545454" />
                    <path id="Vector_11" d="M386.628 416.271C383.632 414.398 379.685 415.309 377.812 418.306C375.939 421.302 376.85 425.249 379.846 427.122C382.843 428.995 386.79 428.084 388.663 425.088C390.536 422.091 389.625 418.144 386.628 416.271Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_12" d="M386.363 416.695C383.601 414.969 379.962 415.808 378.236 418.571C376.509 421.333 377.349 424.972 380.111 426.698C382.874 428.425 386.512 427.585 388.239 424.823C389.965 422.06 389.126 418.422 386.363 416.695Z" stroke="#545454" />
                    <path id="Vector_13" d="M340.68 398.492H392.978C395.739 398.492 397.978 396.254 397.978 393.492V331.98C397.978 329.219 395.739 326.98 392.978 326.98H340.68C337.918 326.98 335.68 329.219 335.68 331.98V393.492C335.68 396.254 337.918 398.492 340.68 398.492Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_14" d="M340.68 397.992H392.978C395.463 397.992 397.478 395.977 397.478 393.492V331.98C397.478 329.495 395.463 327.48 392.978 327.48H340.68C338.194 327.48 336.18 329.495 336.18 331.98V393.492C336.18 395.977 338.194 397.992 340.68 397.992Z" stroke="#545454" />
                    <path id="Vector_15" d="M367.767 347.539H392.213V334.5H367.767V347.539Z" stroke="#545454" />
                    <path id="Vector_16" d="M341.883 347.978H366.329V334.5H341.883V347.978Z" stroke="#545454" />
                    <path id="Vector_17" d="M390.346 395.798H341.996C340.802 395.798 339.657 395.324 338.814 394.48C337.97 393.636 337.496 392.492 337.496 391.298V334.998C337.496 334.866 337.548 334.738 337.642 334.645C337.736 334.551 337.863 334.498 337.996 334.498H394.346C394.478 334.498 394.605 334.551 394.699 334.645C394.793 334.738 394.846 334.866 394.846 334.998V391.298C394.846 392.492 394.371 393.636 393.528 394.48C392.684 395.324 391.539 395.798 390.346 395.798V395.798Z" stroke="#545454" />
                    <path id="Vector_18" d="M394.02 395.228C394.289 395.228 394.508 395.009 394.508 394.74V347.231H393.536V347.715V348.715L392.536 348.715L367.645 348.723V349.311L366.645 349.311L341.628 349.312L340.628 349.312V348.312V347.231H338.178V394.74C338.178 395.009 338.396 395.228 338.666 395.228H394.02ZM394.02 396.228H338.666C337.844 396.228 337.178 395.562 337.178 394.74V346.231H341.628V348.312L366.645 348.311V347.724L392.536 347.715V346.231H395.508V394.74C395.508 395.562 394.841 396.228 394.02 396.228Z" fill="#545454" />
                    <g className="pointer" onClick={() => clickIn("visit-zone-2/1")} onPointerOver={() => hoverIn("visit-zone-2/1")} onPointerEnter={() => hoverIn("visit-zone-2/1")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-2/1")]} title="VISIT"><path id="Ellipse 229" d="M428.575 357.466C426.09 357.466 424.075 359.481 424.075 361.966C424.075 364.451 426.09 366.466 428.575 366.466C431.06 366.466 433.075 364.451 433.075 361.966C433.075 359.481 431.06 357.466 428.575 357.466Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-2/2")} onPointerOver={() => hoverIn("visit-zone-2/2")} onPointerEnter={() => hoverIn("visit-zone-2/2")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-2/2")]} title="VISIT"><path id="Ellipse 213" d="M320.65 368.435C318.165 368.435 316.15 370.449 316.15 372.935C316.15 375.42 318.165 377.435 320.65 377.435C323.136 377.435 325.15 375.42 325.15 372.935C325.15 370.449 323.136 368.435 320.65 368.435Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-2/3")} onPointerOver={() => hoverIn("visit-zone-2/3")} onPointerEnter={() => hoverIn("visit-zone-2/3")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-2/3")]} title="VISIT"><path id="Ellipse 230" d="M433.4 406.603C430.915 406.603 428.9 408.617 428.9 411.103C428.9 413.588 430.915 415.603 433.4 415.603C435.886 415.603 437.9 413.588 437.9 411.103C437.9 408.617 435.886 406.603 433.4 406.603Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-2/4")} onPointerOver={() => hoverIn("visit-zone-2/4")} onPointerEnter={() => hoverIn("visit-zone-2/4")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-2/4")]} title="VISIT"><path id="Ellipse 210" d="M369.348 431.172C366.862 431.172 364.848 433.187 364.848 435.672C364.848 438.157 366.862 440.172 369.348 440.172C371.833 440.172 373.848 438.157 373.848 435.672C373.848 433.187 371.833 431.172 369.348 431.172Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-2/5")} onPointerOver={() => hoverIn("visit-zone-2/5")} onPointerEnter={() => hoverIn("visit-zone-2/5")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-2/5")]} title="VISIT"><path id="Ellipse 212" d="M320.65 409.235C318.165 409.235 316.15 411.25 316.15 413.735C316.15 416.221 318.165 418.235 320.65 418.235C323.136 418.235 325.15 416.221 325.15 413.735C325.15 411.25 323.136 409.235 320.65 409.235Z" fill="#339966" /></Tooltip></g>
                    <path id="Path 728" d="M430.539 314.283H303.796" stroke="#545454" strokeWidth="7" />
                </g>
            </Tooltip>

            <Tooltip visible={hovered.includes('zone-3')} title="Zone 3">
                <g id="zone-3" opacity={opacityClass("zone-3")} onClick={() => hoverIn("zone-3")} onPointerOver={() => hoverIn("zone-3")} onPointerEnter={() => hoverIn("zone-3")}>

                    <path id="Vector_19" d="M428.852 434.907H438.997C440.294 434.907 441.577 435.162 442.775 435.658C443.973 436.154 445.061 436.881 445.978 437.798C446.894 438.715 447.622 439.803 448.118 441.001C448.614 442.198 448.869 443.482 448.869 444.778V444.778C448.869 446.075 448.614 447.358 448.118 448.556C447.622 449.753 446.895 450.841 445.978 451.758C445.062 452.675 443.973 453.402 442.776 453.898C441.578 454.394 440.295 454.649 438.998 454.649H428.852C427.761 454.649 426.715 454.216 425.944 453.445C425.173 452.673 424.739 451.627 424.739 450.536V439.02C424.739 437.929 425.173 436.883 425.944 436.112C426.715 435.341 427.761 434.907 428.852 434.907V434.907Z" fill="white" fillOpacity="0.05" />
                    <path id="Vector_20" d="M428.906 435.407H438.997C440.228 435.407 441.447 435.649 442.584 436.12C443.721 436.591 444.754 437.281 445.624 438.152C446.494 439.022 447.185 440.055 447.656 441.192C448.127 442.329 448.369 443.548 448.369 444.778V444.778C448.369 447.264 447.382 449.647 445.625 451.405C443.867 453.162 441.484 454.149 438.998 454.149H428.906C427.934 454.149 427.001 453.763 426.313 453.075C425.626 452.387 425.239 451.455 425.239 450.482V439.074C425.239 438.102 425.626 437.169 426.313 436.481C427.001 435.794 427.934 435.407 428.906 435.407V435.407Z" stroke="#545454" />
                    <path id="Vector_21" d="M428.852 464.302H438.997C440.294 464.302 441.577 464.557 442.775 465.053C443.973 465.549 445.061 466.276 445.978 467.193C446.894 468.109 447.622 469.197 448.118 470.395C448.614 471.593 448.869 472.876 448.869 474.173V474.173C448.869 475.469 448.614 476.753 448.118 477.95C447.622 479.148 446.895 480.236 445.978 481.153C445.062 482.069 443.973 482.796 442.776 483.292C441.578 483.788 440.295 484.044 438.998 484.044H428.852C427.761 484.044 426.715 483.61 425.944 482.839C425.173 482.068 424.739 481.022 424.739 479.931V468.415C424.739 467.324 425.173 466.278 425.944 465.506C426.715 464.735 427.761 464.302 428.852 464.302V464.302Z" fill="white" fillOpacity="0.05" />
                    <path id="Vector_22" d="M428.906 464.802H438.997C440.228 464.802 441.447 465.044 442.584 465.515C443.721 465.986 444.754 466.676 445.624 467.546C446.494 468.416 447.185 469.449 447.656 470.586C448.127 471.723 448.369 472.942 448.369 474.173V474.173C448.369 476.658 447.382 479.042 445.625 480.799C443.867 482.556 441.484 483.544 438.998 483.544H428.906C427.934 483.544 427.001 483.157 426.313 482.47C425.626 481.782 425.239 480.849 425.239 479.877V468.469C425.239 467.496 425.626 466.563 426.313 465.876C427.001 465.188 427.934 464.802 428.906 464.802V464.802Z" stroke="#545454" />
                    <path id="Path 721" d="M415.823 546.599C396.083 546.599 350.832 546.943 336.869 546.599C322.906 546.255 314.889 536.527 311.112 532.406C307.335 528.285 302.184 511.46 305.733 503.906C309.282 496.352 320.384 487.08 333.892 490.858C347.4 494.636 350.603 504.364 354.492 509.514C358.381 514.664 367.655 515.014 367.655 515.014H415.821L415.823 546.599Z" fill="white" fillOpacity="0.1" stroke="#545454" />
                    <path id="Path 722" d="M304.544 506.881C309.399 508.139 312.944 508.825 315.122 518.098C315.926 521.382 317.386 524.47 319.416 527.174C321.446 529.878 324.002 532.142 326.932 533.831C330.971 536.038 335.51 537.169 340.113 537.114H367.831H415.983" stroke="#545454" />
                    <path id="Path 725" d="M365.5 554.68L264 554.68" stroke="#545454" strokeWidth="7" />
                    <path id="Vector_23" d="M352.44 465.335C352.44 474.375 359.768 481.703 368.808 481.703C377.848 481.703 385.176 474.375 385.176 465.335V464.74C385.176 455.7 377.848 448.372 368.808 448.372C359.768 448.372 352.44 455.7 352.44 464.74V465.335Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_24" d="M352.94 465.335C352.94 474.099 360.044 481.203 368.808 481.203C377.571 481.203 384.676 474.099 384.676 465.335V464.74C384.676 455.976 377.571 448.872 368.808 448.872C360.044 448.872 352.94 455.976 352.94 464.74V465.335Z" stroke="#545454" />
                    <path id="Vector_25" d="M286.103 466.057C281.742 466.057 278.206 469.592 278.206 473.954C278.206 478.315 281.742 481.851 286.103 481.851C290.464 481.851 294 478.315 294 473.954C294 469.592 290.464 466.057 286.103 466.057Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_26" d="M286.103 466.557C282.018 466.557 278.706 469.868 278.706 473.954C278.706 478.039 282.018 481.351 286.103 481.351C290.188 481.351 293.5 478.039 293.5 473.954C293.5 469.868 290.188 466.557 286.103 466.557Z" stroke="#545454" />
                    <g className="pointer" onClick={() => clickIn("visit-zone-3/1")} onPointerOver={() => hoverIn("visit-zone-3/1")} onPointerEnter={() => hoverIn("visit-zone-3/1")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-3/1")]} title="VISIT"><path id="Ellipse 227" d="M453.145 493.031C450.659 493.031 448.645 495.046 448.645 497.531C448.645 500.017 450.659 502.031 453.145 502.031C455.63 502.031 457.645 500.017 457.645 497.531C457.645 495.046 455.63 493.031 453.145 493.031Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-3/2")} onPointerOver={() => hoverIn("visit-zone-3/2")} onPointerEnter={() => hoverIn("visit-zone-3/2")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-3/2")]} title="VISIT"><path id="Ellipse 226" d="M405.323 457.056C402.838 457.056 400.823 459.07 400.823 461.556C400.823 464.041 402.838 466.056 405.323 466.056C407.809 466.056 409.823 464.041 409.823 461.556C409.823 459.07 407.809 457.056 405.323 457.056Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-3/3")} onPointerOver={() => hoverIn("visit-zone-3/3")} onPointerEnter={() => hoverIn("visit-zone-3/3")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-3/3")]} title="VISIT"><path id="Ellipse 221" d="M392.601 493.47C390.115 493.47 388.101 495.484 388.101 497.97C388.101 500.455 390.115 502.47 392.601 502.47C395.086 502.47 397.101 500.455 397.101 497.97C397.101 495.484 395.086 493.47 392.601 493.47Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-3/4")} onPointerOver={() => hoverIn("visit-zone-3/4")} onPointerEnter={() => hoverIn("visit-zone-3/4")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-3/4")]} title="VISIT"><path id="Ellipse 209" d="M337.322 473.728C334.837 473.728 332.822 475.742 332.822 478.228C332.822 480.713 334.837 482.728 337.322 482.728C339.808 482.728 341.822 480.713 341.822 478.228C341.822 475.742 339.808 473.728 337.322 473.728Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-3/5")} onPointerOver={() => hoverIn("visit-zone-3/5")} onPointerEnter={() => hoverIn("visit-zone-3/5")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-3/5")]} title="VISIT"><path id="Ellipse 211" d="M287.308 501.806C284.822 501.806 282.808 503.82 282.808 506.306C282.808 508.791 284.822 510.806 287.308 510.806C289.793 510.806 291.808 508.791 291.808 506.306C291.808 503.82 289.793 501.806 287.308 501.806Z" fill="#339966" /></Tooltip></g>
                </g>
            </Tooltip>

            <Tooltip visible={hovered.includes('zone-4')} title="Zone 4">
                <g id="zone-4" opacity={opacityClass("zone-4")} onClick={() => hoverIn("zone-4")} onPointerOver={() => hoverIn("zone-4")} onPointerEnter={() => hoverIn("zone-4")}>
                    <path id="Vector_32" d="M157.808 460.722C157.808 464.584 160.939 467.716 164.801 467.716C168.664 467.716 171.795 464.584 171.795 460.722V458.639C171.795 454.776 168.664 451.645 164.801 451.645C160.939 451.645 157.808 454.776 157.808 458.639V460.722Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_33" d="M158.308 460.722C158.308 464.308 161.215 467.216 164.801 467.216C168.388 467.216 171.295 464.308 171.295 460.722V458.639C171.295 455.052 168.388 452.145 164.801 452.145C161.215 452.145 158.308 455.052 158.308 458.639V460.722Z" stroke="#545454" />
                    <path id="Vector_34" d="M229.297 527.679V475.48C229.297 472.719 227.058 470.48 224.297 470.48H162.873C160.111 470.48 157.873 472.719 157.873 475.48V527.679C157.873 530.441 160.111 532.679 162.873 532.679H224.297C227.058 532.679 229.297 530.441 229.297 527.679Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_35" d="M228.797 527.679V475.48C228.797 472.995 226.782 470.98 224.297 470.98H162.873C160.388 470.98 158.373 472.995 158.373 475.48V527.679C158.373 530.165 160.388 532.179 162.873 532.179H224.297C226.782 532.179 228.797 530.165 228.797 527.679Z" stroke="#545454" />
                    <path id="Vector_36" d="M178.502 500.633V476.039H165.515V500.633H178.502Z" stroke="#545454" />
                    <path id="Vector_37" d="M179.097 526.525V501.931H165.515V526.525H179.097Z" stroke="#545454" />
                    <path id="Vector_38" d="M226.714 477.861V526.191C226.714 527.385 226.24 528.53 225.396 529.373C224.552 530.217 223.407 530.691 222.214 530.691H166.014C165.881 530.691 165.754 530.639 165.66 530.545C165.567 530.451 165.514 530.324 165.514 530.191V473.861C165.514 473.729 165.567 473.602 165.66 473.508C165.754 473.414 165.881 473.361 166.014 473.361H222.214C223.407 473.361 224.552 473.836 225.396 474.68C226.24 475.523 226.714 476.668 226.714 477.861V477.861Z" stroke="#545454" />
                    <path id="Vector_39" d="M226.214 474.349C226.214 474.08 225.995 473.861 225.726 473.861H178.217V474.836H178.706H179.706L179.706 475.836L179.71 500.726H180.31L180.311 501.726L180.317 526.727L180.318 527.727H179.317H178.242L178.224 530.191H225.726C225.995 530.191 226.214 529.973 226.214 529.703V474.349ZM227.214 474.349V529.703C227.214 530.525 226.548 531.191 225.726 531.191H177.217L177.25 526.727H179.317L179.311 501.726H178.711L178.706 475.836H177.217V472.861H225.726C226.548 472.861 227.214 473.528 227.214 474.349Z" fill="#545454" />
                    <path id="Vector_40" d="M240.393 448.069L235.441 453.568C234.643 454.455 233.525 454.988 232.333 455.051C231.141 455.113 229.973 454.699 229.086 453.901L219.412 445.19C218.525 444.392 217.992 443.273 217.929 442.081C217.867 440.89 218.28 439.722 219.079 438.835L224.03 433.336C225.984 431.166 228.719 429.861 231.635 429.708C234.55 429.554 237.408 430.565 239.578 432.518V432.518C241.748 434.472 243.053 437.208 243.206 440.124C243.359 443.041 242.347 445.898 240.393 448.069V448.069Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_41" d="M240.021 447.734L235.22 453.066C234.47 453.9 233.419 454.401 232.299 454.459C231.179 454.518 230.081 454.13 229.248 453.379L219.91 444.971C219.077 444.221 218.575 443.17 218.517 442.05C218.458 440.93 218.847 439.832 219.597 438.999L224.398 433.667C225.322 432.641 226.439 431.807 227.685 431.213C228.931 430.619 230.281 430.276 231.66 430.203C233.038 430.131 234.418 430.331 235.719 430.792C237.02 431.253 238.218 431.966 239.243 432.89V432.89C241.315 434.755 242.561 437.367 242.707 440.151C242.853 442.934 241.887 445.662 240.021 447.734V447.734Z" stroke="#545454" />
                    <path id="Path 725_2" d="M365.5 554.68L156.527 554.68L156.527 426.68V369.501H195.215" stroke="#545454" strokeWidth="7" />
                    <g className="pointer" onClick={() => clickIn("visit-zone-4/1")} onPointerOver={() => hoverIn("visit-zone-4/1")} onPointerEnter={() => hoverIn("visit-zone-4/1")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-4/1")]} title="VISIT"><path id="Ellipse 204" d="M208.337 393.88C205.852 393.88 203.837 395.895 203.837 398.38C203.837 400.865 205.852 402.88 208.337 402.88C210.822 402.88 212.837 400.865 212.837 398.38C212.837 395.895 210.822 393.88 208.337 393.88Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-4/2")} onPointerOver={() => hoverIn("visit-zone-4/2")} onPointerEnter={() => hoverIn("visit-zone-4/2")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-4/2")]} title="VISIT"><path id="Ellipse 205" d="M191.228 441.262C188.742 441.262 186.728 443.276 186.728 445.762C186.728 448.247 188.742 450.262 191.228 450.262C193.713 450.262 195.728 448.247 195.728 445.762C195.728 443.276 193.713 441.262 191.228 441.262Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-4/3")} onPointerOver={() => hoverIn("visit-zone-4/3")} onPointerEnter={() => hoverIn("visit-zone-4/3")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-4/3")]} title="VISIT"><path id="Ellipse 206" d="M236.415 496.103C233.93 496.103 231.915 498.117 231.915 500.603C231.915 503.088 233.93 505.103 236.415 505.103C238.9 505.103 240.915 503.088 240.915 500.603C240.915 498.117 238.9 496.103 236.415 496.103Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-4/4")} onPointerOver={() => hoverIn("visit-zone-4/4")} onPointerEnter={() => hoverIn("visit-zone-4/4")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-4/4")]} title="VISIT"><path id="Ellipse 207" d="M238.17 540.852C235.685 540.852 233.67 542.866 233.67 545.352C233.67 547.837 235.685 549.852 238.17 549.852C240.655 549.852 242.67 547.837 242.67 545.352C242.67 542.866 240.655 540.852 238.17 540.852Z" fill="#339966" /></Tooltip></g>
                </g>
            </Tooltip>

            <Tooltip visible={hovered.includes('zone-5')} title="Zone 5">
                <g id="zone-5" opacity={opacityClass("zone-5")} onClick={() => hoverIn("zone-5")} onPointerOver={() => hoverIn("zone-5")} onPointerEnter={() => hoverIn("zone-5")}>
                    <path id="balconi" d="M521.043 448.062H576.175V467.601H540.002L539.034 554.455H521.043V448.062Z" fill="white" fillOpacity="0.1" stroke="#545454" />
                    <g id="chair 11">
                        <path id="Vector_42" d="M518.949 536.697L514.516 536.93C513.481 536.984 512.446 536.835 511.469 536.489C510.493 536.144 509.594 535.609 508.824 534.916C508.054 534.223 507.428 533.385 506.981 532.45C506.535 531.515 506.278 530.502 506.224 529.467V529.467C506.169 528.433 506.319 527.397 506.665 526.421C507.011 525.444 507.546 524.545 508.239 523.776C508.933 523.006 509.771 522.38 510.706 521.935C511.642 521.489 512.655 521.232 513.69 521.178L518.124 520.946C518.484 520.927 518.837 521.052 519.105 521.293C519.373 521.535 519.534 521.873 519.553 522.233L520.236 535.268C520.255 535.628 520.13 535.981 519.889 536.249C519.648 536.517 519.31 536.678 518.949 536.697V536.697Z" fill="white" fillOpacity="0.05" />
                        <path id="Vector_43" d="M518.618 536.214L514.489 536.43C513.52 536.482 512.551 536.341 511.636 536.018C510.721 535.694 509.879 535.194 509.158 534.545C508.437 533.896 507.851 533.111 507.433 532.235C507.015 531.359 506.774 530.41 506.723 529.441V529.441C506.672 528.472 506.813 527.503 507.137 526.588C507.46 525.673 507.961 524.831 508.611 524.11C509.26 523.389 510.046 522.803 510.921 522.386C511.797 521.968 512.747 521.728 513.716 521.677L517.845 521.461C517.997 521.453 518.15 521.475 518.295 521.526C518.439 521.577 518.572 521.657 518.686 521.759C518.8 521.861 518.892 521.985 518.958 522.124C519.024 522.262 519.062 522.412 519.07 522.564L519.721 534.988C519.737 535.297 519.63 535.6 519.423 535.83C519.216 536.059 518.927 536.198 518.618 536.214V536.214Z" stroke="#545454" />
                    </g>
                    <g id="chair 10">
                        <path id="Vector_44" d="M500.498 489.011L504.343 486.791C505.24 486.273 506.23 485.936 507.257 485.801C508.284 485.665 509.328 485.733 510.329 486.001C511.33 486.269 512.268 486.731 513.09 487.362C513.912 487.992 514.601 488.779 515.119 489.676V489.676C515.637 490.573 515.974 491.564 516.109 492.591C516.244 493.618 516.175 494.662 515.907 495.662C515.639 496.663 515.176 497.601 514.545 498.423C513.914 499.244 513.127 499.934 512.23 500.451L508.385 502.671C508.072 502.852 507.701 502.9 507.353 502.807C507.004 502.714 506.707 502.486 506.527 502.173L500 490.869C499.82 490.557 499.771 490.186 499.864 489.837C499.958 489.489 500.186 489.192 500.498 489.011V489.011Z" fill="white" fillOpacity="0.05" />
                        <path id="Vector_45" d="M501.013 489.291L504.593 487.224C505.433 486.739 506.361 486.424 507.323 486.296C508.285 486.169 509.262 486.233 510.199 486.484C511.137 486.735 512.015 487.168 512.785 487.759C513.555 488.349 514.201 489.086 514.686 489.926V489.926C515.171 490.766 515.486 491.694 515.613 492.656C515.74 493.618 515.676 494.596 515.424 495.533C515.173 496.47 514.739 497.349 514.148 498.118C513.557 498.888 512.82 499.533 511.98 500.018L508.4 502.085C508.267 502.162 508.121 502.211 507.969 502.231C507.817 502.251 507.663 502.241 507.515 502.202C507.367 502.162 507.228 502.094 507.107 502C506.985 501.907 506.883 501.791 506.807 501.658L500.586 490.884C500.432 490.616 500.39 490.298 500.47 489.999C500.55 489.701 500.745 489.446 501.013 489.291V489.291Z" stroke="#545454" />
                    </g>
                    <g id="chair 9">
                        <path id="Vector_46" d="M518.406 472.213L513.967 472.135C512.931 472.118 511.909 471.896 510.958 471.483C510.008 471.071 509.149 470.475 508.429 469.73C507.709 468.985 507.143 468.105 506.763 467.141C506.383 466.178 506.197 465.148 506.215 464.113V464.113C506.233 463.077 506.455 462.055 506.868 461.104C507.281 460.154 507.878 459.295 508.623 458.575C509.369 457.856 510.248 457.29 511.212 456.911C512.177 456.532 513.206 456.346 514.242 456.365L518.681 456.442C519.042 456.448 519.385 456.598 519.636 456.857C519.886 457.117 520.023 457.465 520.017 457.826L519.789 470.877C519.783 471.237 519.634 471.581 519.374 471.831C519.115 472.082 518.766 472.219 518.406 472.213V472.213Z" fill="white" fillOpacity="0.05" />
                        <path id="Vector_47" d="M518.109 471.707L513.975 471.635C513.005 471.619 512.048 471.411 511.158 471.025C510.268 470.638 509.463 470.08 508.788 469.382C508.114 468.685 507.584 467.861 507.228 466.958C506.873 466.055 506.698 465.091 506.715 464.121V464.121C506.732 463.151 506.94 462.194 507.327 461.304C507.714 460.414 508.272 459.609 508.97 458.935C509.669 458.261 510.493 457.731 511.396 457.376C512.299 457.021 513.263 456.847 514.233 456.864L518.366 456.937C518.52 456.939 518.671 456.972 518.811 457.033C518.951 457.094 519.078 457.182 519.185 457.292C519.291 457.403 519.375 457.533 519.431 457.675C519.487 457.818 519.515 457.97 519.512 458.123L519.295 470.562C519.289 470.871 519.161 471.165 518.939 471.38C518.716 471.595 518.418 471.713 518.109 471.707V471.707Z" stroke="#545454" />
                    </g>
                    <g id="chair 8">
                        <path id="Vector_48" d="M520.558 440.275L522.435 436.251C522.872 435.312 523.49 434.468 524.254 433.768C525.017 433.068 525.911 432.525 526.884 432.171C527.858 431.816 528.891 431.657 529.926 431.702C530.961 431.747 531.977 431.995 532.916 432.433V432.433C533.855 432.871 534.699 433.489 535.399 434.253C536.099 435.017 536.641 435.911 536.995 436.885C537.349 437.859 537.508 438.892 537.463 439.928C537.417 440.963 537.168 441.978 536.73 442.917L534.853 446.941C534.701 447.268 534.425 447.521 534.086 447.644C533.747 447.768 533.373 447.751 533.046 447.599L521.216 442.083C520.889 441.93 520.636 441.654 520.513 441.315C520.389 440.976 520.406 440.602 520.558 440.275V440.275Z" fill="white" fillOpacity="0.05" />
                        <path id="Vector_49" d="M521.141 440.209L522.888 436.463C523.297 435.583 523.876 434.793 524.591 434.137C525.306 433.481 526.144 432.973 527.055 432.64C527.967 432.308 528.935 432.159 529.905 432.201C530.874 432.244 531.826 432.476 532.705 432.886V432.886C533.584 433.296 534.375 433.876 535.03 434.591C535.686 435.307 536.194 436.144 536.525 437.056C536.857 437.968 537.006 438.936 536.963 439.906C536.921 440.875 536.687 441.827 536.277 442.706L534.53 446.453C534.465 446.591 534.373 446.716 534.261 446.819C534.148 446.923 534.015 447.003 533.872 447.055C533.728 447.108 533.575 447.131 533.422 447.125C533.269 447.118 533.119 447.081 532.98 447.017L521.705 441.759C521.424 441.628 521.208 441.391 521.102 441.101C520.996 440.81 521.01 440.489 521.141 440.209V440.209Z" stroke="#545454" />
                    </g>
                    <g id="chair 7">
                        <path id="Vector_50" d="M557.052 446.045L556.975 441.605C556.956 440.569 557.142 439.54 557.521 438.576C557.901 437.612 558.466 436.732 559.186 435.987C559.905 435.242 560.764 434.645 561.714 434.232C562.664 433.819 563.686 433.597 564.722 433.579V433.579C565.758 433.561 566.787 433.747 567.751 434.126C568.715 434.506 569.595 435.072 570.34 435.792C571.085 436.512 571.681 437.371 572.094 438.322C572.506 439.272 572.728 440.294 572.746 441.33L572.823 445.769C572.829 446.13 572.692 446.478 572.442 446.738C572.191 446.997 571.848 447.146 571.487 447.153L558.436 447.381C558.075 447.387 557.727 447.25 557.467 446.999C557.208 446.749 557.059 446.405 557.052 446.045V446.045Z" fill="white" fillOpacity="0.05" />
                        <path id="Vector_51" d="M557.547 445.73L557.475 441.596C557.457 440.626 557.631 439.662 557.987 438.759C558.342 437.856 558.871 437.032 559.545 436.334C560.219 435.636 561.024 435.078 561.914 434.691C562.803 434.304 563.761 434.096 564.731 434.079V434.079C565.701 434.062 566.665 434.236 567.568 434.592C568.471 434.947 569.295 435.477 569.993 436.152C570.69 436.826 571.249 437.631 571.635 438.521C572.022 439.411 572.229 440.368 572.246 441.339L572.318 445.472C572.32 445.625 572.293 445.777 572.237 445.92C572.181 446.062 572.097 446.192 571.991 446.302C571.884 446.412 571.757 446.501 571.617 446.562C571.476 446.623 571.325 446.656 571.172 446.658L558.733 446.875C558.424 446.881 558.125 446.763 557.903 446.548C557.68 446.333 557.552 446.039 557.547 445.73V445.73Z" stroke="#545454" />
                    </g>
                    <g id="chair 14">
                        <path id="Vector_52" d="M582.05 401.745L581.972 406.184C581.954 407.22 581.733 408.242 581.32 409.192C580.908 410.142 580.312 411.002 579.567 411.722C578.822 412.441 577.942 413.007 576.978 413.387C576.014 413.767 574.985 413.953 573.95 413.935V413.935C572.914 413.917 571.891 413.695 570.941 413.282C569.991 412.869 569.132 412.273 568.412 411.527C567.693 410.782 567.127 409.902 566.748 408.938C566.368 407.974 566.183 406.944 566.201 405.909L566.279 401.469C566.285 401.109 566.435 400.765 566.694 400.515C566.953 400.264 567.302 400.127 567.662 400.133L580.713 400.361C581.074 400.367 581.417 400.517 581.668 400.776C581.919 401.036 582.056 401.384 582.05 401.745V401.745Z" fill="white" fillOpacity="0.05" />
                        <path id="Vector_53" d="M581.544 402.042L581.472 406.175C581.456 407.145 581.248 408.103 580.862 408.993C580.475 409.883 579.917 410.688 579.219 411.362C578.522 412.036 577.698 412.566 576.795 412.922C575.892 413.278 574.928 413.452 573.958 413.435V413.435C572.988 413.418 572.031 413.211 571.141 412.824C570.251 412.437 569.446 411.878 568.772 411.18C568.098 410.482 567.568 409.658 567.213 408.755C566.858 407.852 566.684 406.888 566.701 405.917L566.773 401.784C566.776 401.631 566.809 401.48 566.87 401.339C566.931 401.199 567.019 401.072 567.129 400.966C567.239 400.859 567.369 400.776 567.512 400.719C567.654 400.663 567.807 400.636 567.96 400.639L580.399 400.856C580.708 400.861 581.002 400.989 581.217 401.211C581.432 401.434 581.55 401.733 581.544 402.042V402.042Z" stroke="#545454" />
                    </g>
                    <g id="chair 5">
                        <path id="Vector_54" d="M513.625 405.503L512.253 409.726C511.933 410.711 511.423 411.624 510.75 412.412C510.078 413.2 509.256 413.847 508.334 414.318C507.411 414.788 506.404 415.073 505.372 415.154C504.339 415.235 503.3 415.112 502.315 414.792V414.792C501.33 414.472 500.417 413.961 499.63 413.289C498.842 412.616 498.194 411.794 497.724 410.871C497.254 409.948 496.97 408.941 496.89 407.908C496.809 406.876 496.932 405.837 497.253 404.852L498.625 400.629C498.736 400.286 498.979 400.002 499.301 399.838C499.622 399.674 499.995 399.645 500.338 399.756L512.752 403.79C513.095 403.901 513.379 404.144 513.543 404.466C513.707 404.787 513.736 405.16 513.625 405.503V405.503Z" fill="white" fillOpacity="0.05" />
                        <path id="Vector_55" d="M513.055 405.64L511.777 409.571C511.478 410.494 511 411.349 510.37 412.087C509.74 412.825 508.971 413.432 508.107 413.872C507.242 414.313 506.3 414.579 505.332 414.656C504.365 414.732 503.392 414.617 502.47 414.317V414.317C501.547 414.017 500.692 413.539 499.954 412.908C499.216 412.278 498.61 411.509 498.17 410.644C497.729 409.779 497.464 408.837 497.388 407.869C497.312 406.902 497.428 405.929 497.728 405.006L499.005 401.075C499.053 400.929 499.128 400.794 499.228 400.678C499.327 400.562 499.449 400.466 499.585 400.396C499.721 400.327 499.87 400.285 500.023 400.273C500.176 400.261 500.329 400.279 500.475 400.326L512.306 404.171C512.6 404.266 512.844 404.475 512.985 404.75C513.125 405.026 513.15 405.346 513.055 405.64V405.64Z" stroke="#545454" />
                    </g>
                    <g id="chair 4">
                        <path id="Vector_56" d="M548.087 403.813L548.039 406.53C548.004 408.53 547.176 410.435 545.737 411.825C544.298 413.215 542.365 413.976 540.365 413.941V413.941C539.284 413.922 538.218 413.691 537.227 413.26C536.236 412.829 535.339 412.207 534.589 411.429C533.838 410.652 533.248 409.734 532.852 408.729C532.456 407.723 532.262 406.649 532.281 405.569L532.316 403.538C532.332 402.629 532.708 401.763 533.363 401.131C534.017 400.499 534.895 400.153 535.804 400.169L544.718 400.325C545.627 400.341 546.493 400.717 547.125 401.371C547.757 402.026 548.103 402.904 548.087 403.813V403.813Z" fill="white" fillOpacity="0.05" />
                        <path id="Vector_57" d="M547.585 403.898L547.539 406.514C547.507 408.384 546.733 410.164 545.388 411.463C544.042 412.762 542.236 413.474 540.366 413.441V413.441C538.319 413.405 536.369 412.558 534.946 411.084C533.524 409.611 532.745 407.633 532.78 405.585L532.814 403.64C532.821 403.243 532.906 402.851 533.065 402.487C533.223 402.123 533.451 401.794 533.737 401.519C534.022 401.243 534.359 401.026 534.728 400.881C535.098 400.735 535.492 400.664 535.889 400.671L544.616 400.823C545.418 400.837 546.181 401.169 546.737 401.746C547.294 402.322 547.599 403.096 547.585 403.898V403.898Z" stroke="#545454" />
                    </g>
                    <g id="chair 3">
                        <path id="Vector_58" d="M565.865 371.446L566.568 368.822C567.087 366.889 568.351 365.241 570.084 364.241C571.818 363.241 573.877 362.97 575.81 363.488V363.488C576.854 363.768 577.832 364.251 578.69 364.909C579.547 365.567 580.266 366.388 580.807 367.324C581.347 368.26 581.697 369.293 581.838 370.365C581.979 371.437 581.907 372.526 581.627 373.57L581.101 375.532C580.866 376.41 580.291 377.159 579.503 377.614C578.715 378.068 577.779 378.191 576.901 377.956L568.289 375.647C567.41 375.411 566.662 374.836 566.207 374.049C565.752 373.261 565.629 372.325 565.865 371.446V371.446Z" fill="white" fillOpacity="0.05" />
                        <path id="Vector_59" d="M566.372 371.486L567.049 368.958C567.534 367.152 568.716 365.612 570.336 364.677C571.956 363.742 573.881 363.489 575.687 363.973V363.973C577.665 364.503 579.352 365.798 580.376 367.572C581.4 369.346 581.677 371.454 581.146 373.432L580.642 375.312C580.54 375.696 580.362 376.055 580.121 376.37C579.879 376.685 579.578 376.949 579.234 377.147C578.89 377.346 578.511 377.474 578.117 377.526C577.724 377.578 577.324 377.552 576.94 377.449L568.508 375.188C567.734 374.98 567.074 374.474 566.673 373.78C566.273 373.085 566.164 372.26 566.372 371.486V371.486Z" stroke="#545454" strokeWidth="1.00012" />
                    </g>
                    <g id="chair 2">
                        <path id="Vector_60" d="M532.255 374.781V370.341C532.254 369.305 532.458 368.279 532.854 367.322C533.25 366.364 533.831 365.495 534.563 364.762C535.296 364.029 536.165 363.448 537.122 363.051C538.079 362.655 539.105 362.451 540.141 362.451V362.451C541.177 362.45 542.203 362.655 543.16 363.051C544.117 363.448 544.987 364.029 545.719 364.761C546.452 365.494 547.033 366.364 547.429 367.321C547.825 368.279 548.028 369.305 548.028 370.341V374.781C548.028 375.141 547.885 375.487 547.63 375.742C547.374 375.997 547.029 376.141 546.668 376.141H533.615C533.254 376.141 532.908 375.997 532.653 375.742C532.398 375.487 532.255 375.141 532.255 374.781V374.781Z" fill="white" fillOpacity="0.05" />
                        <path id="Vector_61" d="M532.755 374.475V370.341C532.754 369.37 532.945 368.409 533.316 367.513C533.687 366.616 534.231 365.802 534.917 365.115C535.603 364.429 536.417 363.885 537.313 363.513C538.21 363.142 539.171 362.951 540.141 362.951V362.951C541.111 362.95 542.072 363.142 542.969 363.513C543.865 363.884 544.68 364.429 545.366 365.115C546.052 365.801 546.596 366.616 546.967 367.513C547.338 368.409 547.528 369.37 547.528 370.341V374.475C547.528 374.628 547.498 374.779 547.439 374.921C547.381 375.062 547.295 375.191 547.186 375.299C547.078 375.407 546.95 375.493 546.808 375.552C546.667 375.61 546.515 375.641 546.362 375.641H533.921C533.612 375.641 533.315 375.518 533.096 375.299C532.878 375.08 532.755 374.784 532.755 374.475V374.475Z" stroke="#545454" />
                    </g>
                    <g id="chair">
                        <path id="Vector_62" d="M495.116 372.849L495.069 370.132C495.034 368.132 495.795 366.199 497.185 364.76C498.575 363.321 500.479 362.493 502.48 362.458V362.458C503.56 362.439 504.634 362.633 505.639 363.029C506.645 363.425 507.563 364.016 508.34 364.766C509.117 365.517 509.739 366.414 510.17 367.405C510.601 368.396 510.832 369.462 510.851 370.543L510.887 372.574C510.902 373.483 510.556 374.361 509.925 375.015C509.293 375.67 508.427 376.046 507.518 376.062L498.604 376.217C497.695 376.233 496.817 375.887 496.162 375.256C495.508 374.624 495.132 373.758 495.116 372.849V372.849Z" fill="white" fillOpacity="0.05" />
                        <path id="Vector_63" d="M495.614 372.747L495.569 370.131C495.536 368.261 496.247 366.455 497.546 365.109C498.845 363.764 500.626 362.99 502.495 362.958V362.958C504.543 362.922 506.521 363.701 507.994 365.124C509.468 366.546 510.315 368.496 510.351 370.544L510.385 372.489C510.392 372.886 510.321 373.28 510.175 373.65C510.03 374.019 509.813 374.356 509.537 374.641C509.262 374.927 508.932 375.155 508.568 375.313C508.205 375.472 507.813 375.557 507.416 375.564L498.689 375.716C497.887 375.73 497.113 375.425 496.537 374.868C495.96 374.312 495.628 373.549 495.614 372.747V372.747Z" stroke="#545454" />
                    </g>
                    <g id="pink couch">
                        <path id="Vector_64" d="M493.793 341.02H548.285C551.047 341.02 553.285 338.781 553.285 336.02V313.116C553.285 310.354 551.047 308.116 548.285 308.116H493.793C491.032 308.116 488.793 310.354 488.793 313.116V336.02C488.793 338.781 491.032 341.02 493.793 341.02Z" fill="white" fillOpacity="0.1" />
                        <path id="Vector_65" d="M493.793 340.52H548.285C550.77 340.52 552.785 338.505 552.785 336.02V313.116C552.785 310.63 550.77 308.616 548.285 308.616H493.793C491.308 308.616 489.293 310.63 489.293 313.116V336.02C489.293 338.505 491.308 340.52 493.793 340.52Z" stroke="#545454" />
                    </g>
                    <path id="Vector_66" d="M525.646 317.268H548.459C550.848 317.268 552.785 315.331 552.785 312.942C552.785 310.552 550.848 308.616 548.459 308.616H525.646C523.257 308.616 521.32 310.552 521.32 312.942C521.32 315.331 523.257 317.268 525.646 317.268Z" stroke="#545454" />
                    <path id="Vector_67" d="M489.293 336.413C489.293 338.681 491.132 340.52 493.4 340.52C495.668 340.52 497.506 338.681 497.506 336.413V312.723C497.506 310.454 495.668 308.616 493.4 308.616C491.132 308.616 489.293 310.454 489.293 312.723V336.413Z" stroke="#545454" />
                    <path id="Vector_68" d="M530.032 400.248H551.091V378.751H530.032V400.248Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_69" d="M530.532 399.748H550.591V379.251H530.532V399.748Z" stroke="#545454" />
                    <path id="Vector_70" d="M563.813 400.248H584.872V378.751H563.813V400.248Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_71" d="M564.313 399.748H584.372V379.251H564.313V399.748Z" stroke="#545454" />
                    <path id="Vector_72" d="M494.935 400.248H515.994V378.751H494.935V400.248Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_73" d="M495.435 399.748H515.494V379.251H495.435V399.748Z" stroke="#545454" />
                    <path id="Vector_74" d="M510.29 308.116H510.291C514.41 308.116 517.749 304.777 517.749 300.658V300.657C517.749 296.538 514.41 293.199 510.291 293.199H510.29C506.171 293.199 502.832 296.538 502.832 300.657V300.658C502.832 304.777 506.171 308.116 510.29 308.116Z" fill="white" fillOpacity="0.1" />
                    <path id="Vector_75" d="M510.29 307.616H510.291C514.134 307.616 517.249 304.501 517.249 300.658V300.657C517.249 296.814 514.134 293.699 510.291 293.699H510.29C506.447 293.699 503.332 296.814 503.332 300.657V300.658C503.332 304.501 506.447 307.616 510.29 307.616Z" stroke="#545454" />
                    <path id="Vector_76" d="M484.405 277.405H541.439V188.345H484.405V277.405Z" fill="white" fillOpacity="0.2" />
                    <path id="Vector_77" d="M484.905 276.905H540.939V188.845H484.905V276.905Z" stroke="#CCCCCC" />
                    <path id="Path 732" d="M553.235 187.613V290.613H476.271" stroke="#8C8C8C" strokeWidth="3" />
                    <path id="Path 733" d="M420.109 187.611H592.51L592.615 554.68L420.109 554.68" stroke="#545454" strokeWidth="7" />
                    <path id="Path 731" d="M476.202 554.456L476.202 188.441" stroke="#545454" strokeWidth="7" />
                    <g className="pointer" onClick={() => clickIn("visit-zone-5/1")} onPointerOver={() => hoverIn("visit-zone-5/1")} onPointerEnter={() => hoverIn("visit-zone-5/1")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-5/1")]} title="VISIT"><path id="Ellipse 233" d="M489.558 472.851C487.072 472.851 485.058 474.865 485.058 477.351C485.058 479.836 487.072 481.851 489.558 481.851C492.043 481.851 494.058 479.836 494.058 477.351C494.058 474.865 492.043 472.851 489.558 472.851Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-5/2")} onPointerOver={() => hoverIn("visit-zone-5/2")} onPointerEnter={() => hoverIn("visit-zone-5/2")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-5/2")]} title="VISIT"><path id="Ellipse 234" d="M510.616 428.101C508.131 428.101 506.116 430.115 506.116 432.601C506.116 435.086 508.131 437.101 510.616 437.101C513.101 437.101 515.116 435.086 515.116 432.601C515.116 430.115 513.101 428.101 510.616 428.101Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-5/3")} onPointerOver={() => hoverIn("visit-zone-5/3")} onPointerEnter={() => hoverIn("visit-zone-5/3")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-5/3")]} title="VISIT"><path id="Ellipse 235" d="M525.094 357.905C522.608 357.905 520.594 359.92 520.594 362.405C520.594 364.891 522.608 366.905 525.094 366.905C527.579 366.905 529.594 364.891 529.594 362.405C529.594 359.92 527.579 357.905 525.094 357.905Z" fill="#339966" /></Tooltip></g>
                    <g className="pointer" onClick={() => clickIn("visit-zone-5/4")} onPointerOver={() => hoverIn("visit-zone-5/4")} onPointerEnter={() => hoverIn("visit-zone-5/4")}><Tooltip trigger={["hover", "click", isHovered("visit-zone-5/4")]} title="VISIT"><path id="Ellipse 236" d="M567.649 337.724C565.164 337.724 563.149 339.738 563.149 342.224C563.149 344.709 565.164 346.724 567.649 346.724C570.135 346.724 572.149 344.709 572.149 342.224C572.149 339.738 570.135 337.724 567.649 337.724Z" fill="#339966" /></Tooltip></g>
                </g>
            </Tooltip>
        </svg >)
})

export const ActMapFull = React.memo(ActMap)
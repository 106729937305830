import React from 'react'

const ExpandMapIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.5" height="10.5" viewBox="0 0 10.5 10.5">
        <path d="M42,41H52V51" transform="translate(-42 -40.5)" fill="none" stroke="#8c8c8c" strokeWidth="2"/>
    </svg>
  )
}

ExpandMapIcon.propTypes = {
}

ExpandMapIcon.defaultProps = {
}

export {ExpandMapIcon}
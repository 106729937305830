import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { getNetworkDownloadSpeed } from './utils'
import { LoadingPage, VRPage, ProductPage, NavigationPage, AllProductsPage, AnimationPage } from './pages'
import { Player } from './components'
const whyDidYouRender = require('@welldone-software/why-did-you-render')
whyDidYouRender(React, { trackAllPureComponents: false, logOnDifferentValues: true })

const CustomRoute = (props) => {
  return <Route {...props} />
}
const App = () => {
  
  useEffect(() => {
     getNetworkDownloadSpeed()
  }, [])
  return (
    <Router>
      <Player />
      <React.Suspense fallback={<LoadingPage redirect={false} />}>
        <Switch>
          <CustomRoute exact component={AllProductsPage} path="/products/:actId" useChat={true} />
          <CustomRoute exact component={ProductPage} path="/product/:id" useChat={true} />
          <CustomRoute exact component={VRPage} path="/vr/:model/:pano" useChat={true} />
          <CustomRoute exact component={NavigationPage} path="/navigation" />
          <CustomRoute exact component={AnimationPage} path="/animation" />
          <CustomRoute exact component={LoadingPage} path="/" />
        </Switch>
      </React.Suspense>
    </Router>
  )
}
export default React.memo(App)

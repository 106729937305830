import React from 'react'
import PropTypes from 'prop-types'

const PinterestIcon = ({ fill }) => {
  return (
    <svg id="pinterest" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="Path_194" data-name="Path 194" d="M89.876,0a9.977,9.977,0,0,0-3.983,19.079c0-1.867-.013-1.626,1.41-7.7C86.521,9.8,87.129,7.2,88.88,7.2c2.43,0,.779,3.6.5,5.021a1.559,1.559,0,0,0,1.577,1.925c1.909,0,3.153-2.427,3.153-5.272,0-2.176-1.493-3.766-4.066-3.766-4.575,0-5.735,5.1-4.149,6.695.4.6,0,.632,0,1.423-.277.836-2.49-.379-2.49-3.514,0-2.846,2.324-6.192,7.054-6.192a5.924,5.924,0,0,1,6.224,5.69c0,3.933-2.158,6.778-5.311,6.778a2.77,2.77,0,0,1-2.407-1.255c-.6,2.345-.725,3.534-1.66,4.853A10.459,10.459,0,0,0,90.125,20,9.887,9.887,0,0,0,100,10.042,10.281,10.281,0,0,0,89.876,0Z" transform="translate(-80)" fill={fill}/>
    </svg>
  )
}

PinterestIcon.propTypes = {
  fill: PropTypes.string
}

PinterestIcon.defaultProps = {
  fill: "#ccc"
}

export {PinterestIcon}
import React from 'react'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const StyledButton = styled.button`
  width: 44px;
  height: 44px;
  background: none;
  border: none;
  :focus {
    outline: none;
  }
  :hover {
    background-color: #703540;
  }
`;

const NextButton = () => {
  return (
    <StyledButton>
      <FontAwesomeIcon icon={faChevronRight} color='#fff' />
    </StyledButton>
  )
}

export { NextButton }

import React from 'react'
import PropTypes from 'prop-types'

const InfoIcon = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="15" viewBox="0 0 4 15">
      <g id="Group_203" data-name="Group 203" transform="translate(-1831.5 -179.5)">
        <path id="Path_232" data-name="Path 232" d="M16,145h4v-8H16Z" transform="translate(1815.5 49.5)" fill={fill}/>
        <path id="Path_233" data-name="Path 233" d="M16,134h4v-4H16Z" transform="translate(1815.5 49.5)" fill={fill}/>
      </g>
    </svg>
  )
}

InfoIcon.propTypes = {
  fill: PropTypes.string
}

InfoIcon.defaultProps = {
  fill: "#fff"
}

export {InfoIcon}
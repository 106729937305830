import React from 'react'
import PropTypes from 'prop-types'

const LinkedinIcon = ({ fill }) => {
  return (
    <svg id="linkedin" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="Path_190" data-name="Path 190" d="M145,20h-4V13c0-1.92-.847-2.991-2.366-2.991C136.981,10.01,136,11.126,136,13v7h-4V7h4V8.462a4.821,4.821,0,0,1,4.083-2.2c2.829,0,4.917,1.726,4.917,5.3ZM127.442,4.921a2.461,2.461,0,1,1,2.441-2.461A2.451,2.451,0,0,1,127.442,4.921ZM125,20h5V7h-5Z" transform="translate(-125)" fill={fill}/>
    </svg>
  )
}

LinkedinIcon.propTypes = {
  fill: PropTypes.string
}

LinkedinIcon.defaultProps = {
  fill: "#ccc"
}

export {LinkedinIcon}
import React from 'react';


const LogoIcon = React.memo(() => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
            <g id="Group_557" data-name="Group 557" transform="translate(-1028 -481)">
                <g id="Path_1539" data-name="Path 1539" transform="translate(1028 481)" fill="none" opacity="0.1">
                <path d="M17,0A17,17,0,1,1,0,17,17,17,0,0,1,17,0Z" stroke="none"/>
                <path d="M 17 1 C 12.72624969482422 1 8.708290100097656 2.664289474487305 5.686290740966797 5.686290740966797 C 2.664289474487305 8.708290100097656 1 12.72624969482422 1 17 C 1 21.27375030517578 2.664289474487305 25.29170989990234 5.686290740966797 28.3137092590332 C 8.708290100097656 31.3357105255127 12.72624969482422 33 17 33 C 21.27375030517578 33 25.29170989990234 31.3357105255127 28.3137092590332 28.3137092590332 C 31.3357105255127 25.29170989990234 33 21.27375030517578 33 17 C 33 12.72624969482422 31.3357105255127 8.708290100097656 28.3137092590332 5.686290740966797 C 25.29170989990234 2.664289474487305 21.27375030517578 1 17 1 M 17 0 C 26.38883972167969 0 34 7.611160278320312 34 17 C 34 26.38883972167969 26.38883972167969 34 17 34 C 7.611160278320312 34 0 26.38883972167969 0 17 C 0 7.611160278320312 7.611160278320312 0 17 0 Z" stroke="none" fill="#111"/>
                </g>
                <path id="Path_1449" data-name="Path 1449" d="M-246.073,488.606v4.483a4.266,4.266,0,0,0-2.792-1.038,4.287,4.287,0,0,0-4.287,4.287,4.287,4.287,0,0,0,4.287,4.287,4.266,4.266,0,0,0,2.792-1.038v.838h1.981V488.606Zm-2.373,10.207a2.475,2.475,0,0,1-2.475-2.475,2.475,2.475,0,0,1,2.475-2.475,2.475,2.475,0,0,1,2.475,2.475A2.475,2.475,0,0,1-248.446,498.813Z" transform="translate(1288.528 2.384)" fill="#545454" stroke="#545454" strokeMiterlimit="10" strokeWidth="0.5"/>
                <path id="Path_1450" data-name="Path 1450" d="M-213.3,495.919l3.535-3.868h-2.485l-3.414,3.7h-.147v-7.141h-1.98v11.819h1.98v-3.435h.7l2.893,3.435h2.626Z" transform="translate(1263.676 2.384)" fill="#545454" stroke="#545454" strokeMiterlimit="10" strokeWidth="0.5"/>
            </g>
        </svg>
    )
})

const HeartIcon = React.memo(() => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
            <g id="Group_558" data-name="Group 558" transform="translate(-1028 -548)">
                <path id="Path_696" data-name="Path 696" d="M26.993,14.9C26.811,18.48,23.225,21.334,20,24c-1.7,1.4-2.795,1.4-4.5,0-3.2-2.624-6.358-5.506-6.5-9.212-.173-4.656,5.451-6.562,8.646-2.763.35.475.35.475.7,0C21.531,8.208,27.235,10.14,26.993,14.9Z" transform="translate(1027.5 547.5)" fill="#545454" fill-rule="evenodd"/>
                <g id="Group_196" data-name="Group 196" transform="translate(1027.5 547.5)" opacity="0.1">
                <g id="Rectangle_274" data-name="Rectangle 274" transform="translate(0.5 0.5)" fill="none" stroke="#545454" stroke-width="1">
                    <rect width="34" height="34" rx="17" stroke="none"/>
                    <rect x="0.5" y="0.5" width="33" height="33" rx="16.5" fill="none"/>
                </g>
                </g>
            </g>
            </svg>

    )
})

const ChatIcon = React.memo(() => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="33.999" height="36.763" viewBox="0 0 33.999 36.763">
        <g id="Group_559" data-name="Group 559" transform="translate(-1028.001 -612.999)">
          <g id="Union_13" data-name="Union 13" transform="translate(-2967 -11057)" fill="rgba(17,17,17,0.5)">
            <path d="M 4012.2265625 11705.84375 L 4010.802490234375 11703.6513671875 L 4010.6708984375 11703.44921875 L 4010.43017578125 11703.42578125 C 4006.361572265625 11703.0419921875 4002.59521484375 11701.1630859375 3999.824462890625 11698.134765625 C 3997.03662109375 11695.087890625 3995.501220703125 11691.1337890625 3995.501220703125 11687.0009765625 C 3995.501220703125 11682.5927734375 3997.21728515625 11678.44921875 4000.333251953125 11675.33203125 C 4003.449462890625 11672.2158203125 4007.5927734375 11670.4990234375 4012.00048828125 11670.4990234375 C 4016.408203125 11670.4990234375 4020.551513671875 11672.2158203125 4023.667724609375 11675.33203125 C 4026.78369140625 11678.44921875 4028.499755859375 11682.5927734375 4028.499755859375 11687.0009765625 C 4028.499755859375 11695.318359375 4022.284912109375 11702.357421875 4014.043212890625 11703.375 L 4013.81201171875 11703.4033203125 L 4013.685302734375 11703.5986328125 L 4012.2265625 11705.84375 Z" stroke="none"/>
            <path d="M 4012.2265625 11704.92578125 L 4013.265869140625 11703.326171875 L 4013.519775390625 11702.935546875 L 4013.98193359375 11702.87890625 C 4021.973388671875 11701.892578125 4027.999755859375 11695.06640625 4027.999755859375 11687.0009765625 C 4027.999755859375 11682.7265625 4026.335693359375 11678.7080078125 4023.314208984375 11675.685546875 C 4020.29248046875 11672.6630859375 4016.2744140625 11670.9990234375 4012.00048828125 11670.9990234375 C 4007.7265625 11670.9990234375 4003.70849609375 11672.6630859375 4000.686767578125 11675.685546875 C 3997.665283203125 11678.7080078125 3996.001220703125 11682.7265625 3996.001220703125 11687.0009765625 C 3996.001220703125 11691.0087890625 3997.489990234375 11694.8427734375 4000.193359375 11697.7978515625 C 4002.880126953125 11700.734375 4006.5322265625 11702.5556640625 4010.477294921875 11702.9287109375 L 4010.95849609375 11702.9736328125 L 4011.221923828125 11703.37890625 L 4012.2265625 11704.92578125 M 4012.2265625 11706.76171875 L 4010.38330078125 11703.923828125 C 4001.75244140625 11703.109375 3995.001220703125 11695.8427734375 3995.001220703125 11687.0009765625 C 3995.001220703125 11677.611328125 4002.610595703125 11669.9990234375 4012.00048828125 11669.9990234375 C 4021.390380859375 11669.9990234375 4028.999755859375 11677.611328125 4028.999755859375 11687.0009765625 C 4028.999755859375 11695.67578125 4022.501220703125 11702.8349609375 4014.1044921875 11703.87109375 L 4012.2265625 11706.76171875 Z" stroke="none" fill="#c4c4c4"/>
          </g>
          <path id="Path_1579" data-name="Path 1579" d="M5.43,1.986a7.051,7.051,0,0,1,4.6-1.549,7.051,7.051,0,0,1,4.6,1.549,4.666,4.666,0,0,1,1.9,3.732,4.666,4.666,0,0,1-1.9,3.732A7.051,7.051,0,0,1,10.025,11a7.719,7.719,0,0,1-2.717-.482,5.682,5.682,0,0,1-3.58,1.295.169.169,0,0,1-.1-.038.252.252,0,0,1-.076-.089.184.184,0,0,1,.038-.216q.089-.089.3-.355a7.241,7.241,0,0,0,.571-.889,5.119,5.119,0,0,0,.508-1.181A4.573,4.573,0,0,1,3.525,5.719,4.666,4.666,0,0,1,5.43,1.986Z" transform="translate(1035 624)" fill="#c4c4c4"/>
        </g>
      </svg>
      
    )
})

export {
    ChatIcon, HeartIcon, LogoIcon
}
import React from 'react'
import styled from 'styled-components'
import { Divider } from 'antd'
const noResultsLogo = require('../../assets/images/logo-no-results.png')

const StyledLogo = styled.div`
  width: 100%;
  background-color: #f4f4f4;

  .help {
    text-align: center;
    font: normal normal normal 14px/22px DIN2014;
    letter-spacing: 0.21px;
    color: #777777;
  }

  img {
    width: 100%;
    margin: 0;
    padding: 5em;
    opacity: 0.75;
  }
`

const NoResults = () => {
  return (
    <React.Fragment>

    <StyledLogo>
      <h4 className="help">save your favourite items here to view later...</h4>
      <Divider />
      <h4 className="help">wishlist...</h4>
      <img src={noResultsLogo} alt=""/>
    </StyledLogo>

    </React.Fragment>
  )
}

export { NoResults }
import React from 'react'
import styled from 'styled-components'
import { isMobile, mobileClass } from '../../../utils'
import { BackIcon, CloseMenuIcon, InstagramIcon, LinkedinIcon, PinterestIcon } from '../..'
import { Button, Checkbox, Input, Drawer } from 'antd'
import { sendContactAPI } from '../../../effects/actions'
import { connect } from 'react-redux'

const MenuFooter = styled.footer`height: 120px;
 position: absolute; left:0; right:0; bottom: 0; .mobile{height: 64px}; color: #C4C4C4; margin-bottom:0px;`
const MenuHeader = styled.header`
  height: 34px;
  position: absolute;
  top: 50px;
  left:25px;
  right:25px;
  bottom: 0;
  &.mobile{
    height: 25px;
    top: 15px;
    left:15px;
    right: 15px;
  } 
  .icon {
    img {
      width: 100%; 
      padding: 0; 
      position:absolute;
    } 
    opacity: 0.5;
    &:hover{
      opacity:1;
      transition:0.3s all;
      svg{
        path{
          fill: #494949;
          cursor: pointer;
        }
      }
    }
    transition:0.3s all;
  }`
const MainContent = styled.main`position: absolute; overflow: auto; left: 80px;right:25px;bottom: 120px;top:120px;padding-right:14px;padding-top:40px; &.mobile{top:80px;padding-top:30px; left:30px;right:15px} select,textarea,input,p{font-family:DIN2014; font-size:14px}`
const ActionButtonLeft = styled.div`
display: flex;
justify-content: center;
align-items: center;
position: absolute;
 left:${p => 42 * p.left}px;
 top:0;
 width: 36px;
 height: 36px; 
 &.mobile{width:20px; height:20px;top:0; left:${p => 30 * p.right}px; border: 1px solid #eee; border-radius: 50%;}
.menu-top{
  margin-left: 15px;
  &:hover{
    opacity:1;
    cursor: pointer;
    transition:0.3s all;
    svg{
      rect{
        fill: #e3e3e3 !important;
      }
      path{
        stroke: #121212 !important;
        cursor: pointer;
      }
      
    }
  }
}
`
const ActionButtonRight = styled.div`display: flex;justify-content: center;align-items: center;position: absolute; right:${p => 42 * p.right}px; top:0; width: 36px; height: 36px; &.mobile{width:20px; height:20px;top:0; right:${p => 30 * p.right}px}`
const MenuItem = styled.li`
cursor:pointer;
display:block;
font-size:30px;
font-family: 'Catamaran', sans-serif !important;
color:#CCCCCC;
margin-bottom:30px;
letter-spacing: -1px;
transition: 0.3s;
&:hover {
  color: black;
  padding-left: 1em;
}
&.without-transition{
  &:hover {
    color: #CCCCCC;
    padding-left: 0;
  }
}
`
const SecondaryButton = styled(Button)`
  margin-top: 8px;
  margin-bottom: 8px;
  height: 40px;
  background: transparent;
  border: 1px solid #339966;
  font-size:14px;
  display: inline-flex;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  width: 100% !important;
  font-family:Radnika-Regular;
  color: #339966;
  text-transform: uppercase;
  :focus,:hover{
    outline:none;
    border:1px solid #339966;
    color:#339966!important
  }
`

const MenuComponent = React.memo(({ closeMenu, active, visitor, sendContactAPI }) => {
  return (
    <Drawer id="drawer" className={mobileClass("")} placement="left" closable={true} width={isMobile() ? "100%" : "40%"} height="100%" closeIcon={null} onClose={closeMenu} visible={active}>
      {active && <MenuContent closeMenu={closeMenu} visitor={visitor} sendContactRequest={sendContactAPI} />}
    </Drawer>
  );
})

const MenuContent = ({ closeMenu, visitor, sendContactRequest }) => {
  const [menu, setMenu] = React.useState()
  const goBackMenu = () => setMenu()
  return (
    <React.Fragment>
      <MenuHeader className={mobileClass("")}>
        {!menu && (
          <ActionButtonLeft onClick={closeMenu} left={0} border={true} className={mobileClass("icon menu-top")}>
            <CloseMenuIcon fill={"transparent"} stroke="#ababab" />
          </ActionButtonLeft>
        )}
        {menu && (
          <ActionButtonLeft onClick={goBackMenu} left={0} className={mobileClass("icon menu-top")}>
            <BackIcon border={true} />
          </ActionButtonLeft>
        )}

        <ActionButtonRight right={2} className={mobileClass("icon border-width-0")} onClick={() => window.open("https://www.instagram.com/domkapa/", "_blank")}>
          <InstagramIcon />
        </ActionButtonRight>
        <ActionButtonRight right={1} className={mobileClass("icon border-width-0")} onClick={() => window.open("https://www.pinterest.pt/domkapa/", "_blank")}>
          <PinterestIcon />
        </ActionButtonRight>
        <ActionButtonRight right={0} className={mobileClass("icon border-width-0")} onClick={() => window.open("https://www.linkedin.com/company/domkapa/", "_blank")}>
          <LinkedinIcon />
        </ActionButtonRight>
      </MenuHeader>

      <MainContent className={mobileClass("")}>
        <MenuOptions menu={menu} setMenu={setMenu} visitor={visitor} sendContactRequest={sendContactRequest} />
      </MainContent>

      <MenuFooter className={mobileClass("text din size-12 text-center d-flex flex-column justify-content-center")}>
      <div className="mb-2 pointer">
          <span onClick={() => window.open('https://domkapa.pt/en/terms-and-conditions-domkapa/?utm_source=virtual-tour-2021&utm_medium=online&utm_campaign=domkapa-immersive-experience', '_blank')}>
            Terms and Conditions | © 2021 Domkapa. All rights reserved.
          </span>
        </div>
        <div className="mb-2 pointer">
          <span onClick={() => window.open('https://vr360.pt', '_blank')}>POWERED BY VR360</span>
        </div>
      </MenuFooter>
    </React.Fragment>
  )
}

const MenuOptions = ({ menu, setMenu, visitor, sendContactRequest }) => {
  const [contactData, setContactData] = React.useState({
    name: visitor?.name || "",
    email: visitor?.email || "",
    position: visitor?.position || "",
    phone_number: "",
    country: "",
    company: "",
    message: "",
  })

  const onChange = event => {
    const { name, value } = event.target;

    setContactData(previousState => ({
      ...previousState,
      [name]: value.trim()
    }))
  }

  switch (menu) {
    case 'company':
      return (
        <React.Fragment>
          <MenuItem onClick={() => setMenu('company')}  className="without-transition">The Company Story</MenuItem>
          <p>Established in 2009, Domkapa is an upholstery specialized brand based in Northern Portugal. We value the combination of four main elements: manual sewing techniques, high design solutions, comfort, and high-quality materials.</p>
          <p>Domkapa started its path by doing Private Label services producing furniture and upholstery items. This long and fruitful path, full of experience and achievements triggered the born of a New Brand's Identity and Independence, in 2017, focused on creating the perfect meeting between comfort and design.</p>
          <p>Domkapa holds a tremendous passion for detailed stitching, presenting several similarities to the haute-couture philosophy focused on the value of a "made by hand".</p>
          <p>Brand's philosophy is adding value to each item, making it one-of-a-kind.</p>
          <br />
        </React.Fragment>
      )
    case 'catalogue':
      return (
        <React.Fragment>
          <MenuItem onClick={() => setMenu('catalogue')} className="without-transition">2021 Catalogue</MenuItem>
          <p>A new catalogue is launched!</p>
          <p>Download the online version and be inspired by new pieces and stylish ambiances while getting all the technical information you need to include our products in your design project.</p>
          <br />
          <SecondaryButton block href="https://domkapa.pt/en/catalogues/" target="_blank">DOWNLOAD</SecondaryButton>
        </React.Fragment>
      )
    case 'materials':
      return (
        <React.Fragment>
          <MenuItem onClick={() => setMenu('materials')} className="without-transition">Materials & Finishes</MenuItem>
          <p>Domkapa's design team works side to side with the production, and this organic synergy allows the creation of innovative designs.</p>
          <p>Every piece is carefully designed to be handmade produced by experienced artisans with ancient techniques and premium materials. With that in mind, besides being customizable in dimensions and shapes, all of Domkapa's products can be customized in materials and finishes. COM and COL are also a possibility.</p>
          <p>Discover below the great offer Domkapa has for you!</p>
          <br />
          <SecondaryButton block href="https://domkapa.pt/en/materials-and-finishes/" target="_blank">EXPLORE PREMIUM COLLECTION</SecondaryButton>
        </React.Fragment>
      )
    case 'contacts':
      return (
        <React.Fragment>
          <MenuItem onClick={() => setMenu('contacts')}  className="without-transition">Contacts</MenuItem>
          <Input onChange={onChange} name="name" className="mb-4" width="100%" placeholder="Name" value={contactData.name} />
          <Input onChange={onChange} name="email" className="mb-4" width="100%" placeholder="E-mail" value={contactData.email} />
          <Input onChange={onChange} name="phone_number" className="mb-4" width="100%" placeholder="Phone Number" />
          <Input onChange={onChange} name="country" className="mb-4" width="100%" placeholder="Country*" />
          <Input onChange={onChange} name="position" className="mb-4" width="100%" placeholder="Occupation*" value={contactData.position} />
          <Input onChange={onChange} name="company" className="mb-4" width="100%" placeholder="Company" />
          <Input.TextArea width="100%" onChange={onChange} rows={4} name="company" placeholder="Your Message" />
          <br />
          <div className="mt-2 mb-5">
            <Checkbox>
              
              I agree to the terms and conditions.</Checkbox>
          </div>
          <SecondaryButton block onClick={() => sendContactRequest(contactData)}>SEND</SecondaryButton>
        </React.Fragment>
      )
    default: return (
      <React.Fragment>
        <MenuItem onClick={() => setMenu('company')}>The Company Story</MenuItem>
        <MenuItem onClick={() => setMenu('catalogue')}>2021 Catalogue</MenuItem>
        <MenuItem onClick={() => setMenu('materials')}>Materials & Finishes</MenuItem>
        <MenuItem onClick={() => setMenu('contacts')}>Contacts</MenuItem>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ visitorAPI }) => {
  const { visitor } = visitorAPI

  return {
    visitor
  }
}

export default connect(mapStateToProps, {
  sendContactAPI
})(React.memo(MenuComponent))

import React from 'react'
const HotspotIcon = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22.5" cy="22.5" r="22" stroke="white" />
      <circle cx="22.8211" cy="22.8211" r="12.6786" fill="white" stroke="white" />
    </svg>
  )
}

export { HotspotIcon }
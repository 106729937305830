import FavoritesComponent from './favorites'
import HeaderComponent from './header'
import VRComponent from './vr'
import PlayerComponent from './player'
import ProductViewComponent from './product-view'
import ProductCI360Component from './product-ci-360'

export {
  FavoritesComponent as Favorites,
  HeaderComponent as Header,
  VRComponent as VR,
  PlayerComponent as Player,
  ProductViewComponent as ProductView,
  ProductCI360Component as ProductCI360
}

export * from './icons'
export * from './acts-map'


import React from 'react'
import PropTypes from 'prop-types'

const MenuIcon = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="35px">
      <g transform="translate(-637 205)">
        <g fill={fill} transform="translate(642.147 -199.118)">
          <g transform="translate(3.853 0.118)">
            <rect stroke="none" width="7" height="1"/>
            <rect fill="none" x="0.5" y="0.5" width="6"/>
          </g>
          <g transform="translate(3.853 12.118)">
            <rect stroke="none" width="7" height="1"/>
            <rect fill="none" x="0.5" y="0.5" width="6"/>
          </g>
          <g transform="translate(-0.147 6.118)">
            <rect stroke="none" width="15" height="1"/>
            <rect fill="none" x="0.5" y="0.5" width="14"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

MenuIcon.propTypes = {
  fill: PropTypes.string
}

MenuIcon.defaultProps = {
  fill: "#2a2a2a"
}

export {MenuIcon}
 
import React from 'react'

const ChatIcon = () => {

    return (
        <svg id="Group_581" data-name="Group 581" xmlns="http://www.w3.org/2000/svg" width="22" height="25" viewBox="0 0 30.001 33.001">
            <g id="Union_6" data-name="Union 6" transform="translate(-4711.999 15729.001)" fill="rgba(17,17,17,0.5)">
                <path d="M 4727.47998046875 -15696.9013671875 L 4726.4140625 -15698.5 L 4726.9990234375 -15698.5 L 4726.9990234375 -15699.5 C 4723.12646484375 -15699.5 4719.48583984375 -15701.0078125 4716.74658203125 -15703.7470703125 C 4714.00732421875 -15706.4853515625 4712.49853515625 -15710.1279296875 4712.49853515625 -15714.0009765625 C 4712.49853515625 -15717.873046875 4714.00732421875 -15721.513671875 4716.74658203125 -15724.2529296875 C 4719.486328125 -15726.9921875 4723.126953125 -15728.5009765625 4726.9990234375 -15728.5009765625 C 4730.8720703125 -15728.5009765625 4734.513671875 -15726.9921875 4737.2529296875 -15724.2529296875 C 4739.9912109375 -15721.513671875 4741.49951171875 -15717.873046875 4741.49951171875 -15714.0009765625 C 4741.49951171875 -15710.126953125 4739.9912109375 -15706.4853515625 4737.2529296875 -15703.7470703125 C 4734.51416015625 -15701.0078125 4730.873046875 -15699.5 4726.9990234375 -15699.5 L 4726.9990234375 -15698.5 L 4728.54443359375 -15698.5 L 4727.47998046875 -15696.9013671875 Z" stroke="none"/>
                <path d="M 4726.9990234375 -15700 C 4730.7392578125 -15700 4734.25537109375 -15701.4560546875 4736.8994140625 -15704.1005859375 C 4739.54345703125 -15706.744140625 4740.99951171875 -15710.2607421875 4740.99951171875 -15714.0009765625 C 4740.99951171875 -15717.7392578125 4739.54345703125 -15721.2548828125 4736.8994140625 -15723.8994140625 C 4734.2548828125 -15726.5439453125 4730.73876953125 -15728.0009765625 4726.9990234375 -15728.0009765625 C 4723.2607421875 -15728.0009765625 4719.7451171875 -15726.5439453125 4717.10009765625 -15723.8994140625 C 4714.455078125 -15721.25390625 4712.99853515625 -15717.7392578125 4712.99853515625 -15714.0009765625 C 4712.99853515625 -15710.2607421875 4714.455078125 -15706.7451171875 4717.10009765625 -15704.1005859375 C 4719.74462890625 -15701.4560546875 4723.26025390625 -15700 4726.9990234375 -15700 M 4727.48046875 -15696 L 4725.4794921875 -15699 L 4726.9990234375 -15699 L 4729.47802734375 -15699 L 4727.48046875 -15696 Z M 4726.9990234375 -15699 C 4718.71728515625 -15699 4711.99853515625 -15705.71484375 4711.99853515625 -15714.0009765625 C 4711.99853515625 -15722.2822265625 4718.71728515625 -15729.0009765625 4726.9990234375 -15729.0009765625 C 4735.28466796875 -15729.0009765625 4741.99951171875 -15722.2822265625 4741.99951171875 -15714.0009765625 C 4741.99951171875 -15705.71484375 4735.28466796875 -15699 4726.9990234375 -15699 Z" stroke="none" fill="#c4c4c4"/>
            </g>
            <path id="Path_1579" data-name="Path 1579" d="M5.43,1.986a7.051,7.051,0,0,1,4.6-1.549,7.051,7.051,0,0,1,4.6,1.549,4.666,4.666,0,0,1,1.9,3.732,4.666,4.666,0,0,1-1.9,3.732A7.051,7.051,0,0,1,10.025,11a7.719,7.719,0,0,1-2.717-.482,5.682,5.682,0,0,1-3.58,1.295.169.169,0,0,1-.1-.038.252.252,0,0,1-.076-.089.184.184,0,0,1,.038-.216q.089-.089.3-.355a7.241,7.241,0,0,0,.571-.889,5.119,5.119,0,0,0,.508-1.181A4.573,4.573,0,0,1,3.525,5.719,4.666,4.666,0,0,1,5.43,1.986Z" transform="translate(4.975 9.001)" fill="#c4c4c4"/>
        </svg>

    )
}

export {ChatIcon}
import React from 'react'
import { ViewProduct, VisitPoint } from '../act-1'
const catalog = require('./../../../assets/mock-data/catalog.json')

const ActMap = ({ height, width, setActiveProduct, hoverProduct, history = {} }) => {
  const [hovered, setHover] = React.useState("")
  const findProduct = (id) => catalog.find(x => x.id === id)
  const clickIn = (id) => { hoverProduct && setHover(); if (history?.push && id?.startsWith('visit-')) history.push(`/vr/${id.replace('visit-', '', 1)}`) }
  const hoverIn = (id) => { if (hovered === id) return; hoverProduct && setHover(id); setActiveProduct && setActiveProduct(findProduct(id)) }

  return (
    <svg width={width} height={height} viewBox="0 0 220 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ViewProduct hovered={hovered} hoverIn={hoverIn} name="act2-miller-armchair">
        <path d="M31.6798 192.303L38.0941 188.136C43.5109 184.618 45.0496 177.374 41.531 171.957L39.5311 168.879C36.0125 163.462 28.7689 161.923 23.3521 165.442L16.9378 169.608C11.521 173.127 9.98226 180.371 13.5009 185.787L15.5008 188.866C19.0194 194.283 26.263 195.822 31.6798 192.303Z" stroke="#545454" />
      </ViewProduct>

      <ViewProduct hovered={hovered} hoverIn={hoverIn} name="act2-pouf-angles">
        <path d="M150.517 166.762C146.606 166.762 143.435 169.933 143.435 173.844C143.435 177.755 146.606 180.926 150.517 180.926C154.428 180.926 157.599 177.755 157.599 173.844C157.599 169.933 154.428 166.762 150.517 166.762Z" stroke="#545454" />
        <path d="M133.054 157.604L139.674 161.742L139.415 169.519L132.538 173.16L125.917 169.022L126.177 161.245L133.054 157.604Z" stroke="#545454" />

      </ViewProduct>

      <ViewProduct hovered={hovered} hoverIn={hoverIn} name="act2-mano-side-table">
        <path transform="translate(-85 -100)" d="M132.561 172.518L138.869 169.178L139.107 162.044L133.031 158.246L126.722 161.586L126.484 168.72L132.561 172.518ZM132.515 173.802L125.35" stroke="#545454" />
      </ViewProduct>

      <ViewProduct hovered={hovered} hoverIn={hoverIn} name="act2-echo-bed">
        <path d="M85.5032 138.851H143.728C146.495 138.851 148.738 136.608 148.738 133.841V65.3579C148.738 62.591 146.495 60.3479 143.728 60.3479L85.5032 60.3479C82.7362 60.3479 80.4932 62.591 80.4932 65.3579L80.4932 133.841C80.4932 136.608 82.7362 138.851 85.5032 138.851Z" stroke="#545454" />
        <path d="M140.798 136.409H86.9684C85.6396 136.409 84.3653 135.881 83.4258 134.941C82.4862 134.002 81.9584 132.727 81.9584 131.399V68.718C81.9584 68.5703 82.017 68.4287 82.1214 68.3243C82.2258 68.22 82.3674 68.1613 82.515 68.1613L145.251 68.1613C145.399 68.1613 145.541 68.22 145.645 68.3243C145.749 68.4287 145.808 68.5703 145.808 68.718V131.399C145.808 132.727 145.28 134.002 144.341 134.941C143.401 135.881 142.127 136.409 140.798 136.409V136.409Z" stroke="#545454" />
        <path d="M144.888 135.774C145.188 135.774 145.432 135.53 145.432 135.231V82.3372H144.35V82.8763V83.9893L143.237 83.9897L115.525 83.9987V84.6533L114.412 84.6533L86.5594 84.6539L85.4461 84.654V83.5406V82.3372H82.7177V135.231C82.7177 " stroke="#545454" />
        <path d="M115.66 82.6803H142.877V68.1635H115.66V82.6803Z" stroke="#545454" />
        <path d="M86.8425 83.1691H114.059V68.1635H86.8425V83.1691Z" stroke="#545454" />
      </ViewProduct>

      <path className="no-touch" d="M185.547 45.6553L44.4393 45.6553" stroke="#545454" strokeWidth="7" />
      <rect className="no-touch" x="3.5" y="45.5" width="213" height="160" stroke="#A5A6A7" strokeOpacity="0.25" strokeWidth="7" />

      <VisitPoint hovered={hovered} clickIn={clickIn} hoverIn={hoverIn} url="visit-zone-2/4"><path d="M183.36 93.7319C180.593 93.7319 178.35 95.975 178.35 98.7419C178.35 101.509 180.593 103.752 183.36 103.752C186.127 103.752 188.37 101.509 188.37 98.7419C188.37 95.975 186.127 93.7319 183.36 93.7319Z" fill="#339966" /></VisitPoint>
      <VisitPoint hovered={hovered} clickIn={clickIn} hoverIn={hoverIn} url="visit-zone-2/1"><path d="M63.2041 105.944C60.4372 105.944 58.1941 108.187 58.1941 110.954C58.1941 113.721 60.4372 115.964 63.2041 115.964C65.9711 115.964 68.2141 113.721 68.2141 110.954C68.2141 108.187 65.9711 105.944 63.2041 105.944Z" fill="#339966" /></VisitPoint>
      <VisitPoint hovered={hovered} clickIn={clickIn} hoverIn={hoverIn} url="visit-zone-2/5"><path d="M188.732 148.437C185.965 148.437 183.722 150.681 183.722 153.447C183.722 156.214 185.965 158.457 188.732 158.457C191.499 158.457 193.742 156.214 193.742 153.447C193.742 150.681 191.499 148.437 188.732 148.437Z" fill="#339966" /></VisitPoint>
      <VisitPoint hovered={hovered} clickIn={clickIn} hoverIn={hoverIn} url="visit-zone-2/3"><path d="M117.42 175.791C114.653 175.791 112.41 178.034 112.41 180.801C112.41 183.568 114.653 185.811 117.42 185.811C120.187 185.811 122.43 183.568 122.43 180.801C122.43 178.034 120.187 175.791 117.42 175.791Z" fill="#339966" /></VisitPoint>
      <VisitPoint hovered={hovered} clickIn={clickIn} hoverIn={hoverIn} url="visit-zone-2/2"><path d="M63.2041 151.369C60.4372 151.369 58.1941 153.612 58.1941 156.379C58.1941 159.146 60.4372 161.389 63.2041 161.389C65.9711 161.389 68.2141 159.146 68.2141 156.379C68.2141 153.612 65.9711 151.369 63.2041 151.369Z" fill="#339966" /></VisitPoint>
    </svg>
  )
}

export const ActMap2 = React.memo(ActMap)
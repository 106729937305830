import React from 'react'
import { VisitPoint } from '../act-1'
const catalog = require('./../../../assets/mock-data/catalog.json')

const ActMap = ({ height, width, setActiveProduct, hoverProduct, history = {} }) => {
    const [hovered, setHover] = React.useState("")
    const findProduct = (id) => catalog.find(x => x.id === id)
    const clickIn = (id) => { hoverProduct && setHover(); if (history?.push && id?.startsWith('visit-')) history.push(`/vr/${id.replace('visit-', '', 1)}`) }
    const hoverIn = (id) => { if (hovered === id) return; hoverProduct && setHover(id); setActiveProduct && setActiveProduct(findProduct(id)) }

    return (
        <svg width={width} height={height} viewBox="0 0 220 158" fill="none" xmlns="http://www.w3.org/2000/svg">
            <VisitPoint hovered={hovered} clickIn={clickIn} hoverIn={hoverIn} url="visit-production/4"><path d="M91.6028 138.374C89.8619 138.374 88.4506 139.786 88.4506 141.527C88.4506 143.267 89.8619 144.679 91.6028 144.679C93.3438 144.679 94.7551 143.267 94.7551 141.527C94.7551 139.786 93.3438 138.374 91.6028 138.374Z" fill="#339966" /></VisitPoint>
            <VisitPoint hovered={hovered} clickIn={clickIn} hoverIn={hoverIn} url="visit-production/3"><path d="M112.808 97.1928C111.067 97.1928 109.655 98.6041 109.655 100.345C109.655 102.086 111.067 103.497 112.808 103.497C114.549 103.497 115.96 102.086 115.96 100.345C115.96 98.6041 114.549 97.1928 112.808 97.1928Z" fill="#339966" /></VisitPoint>
            <VisitPoint hovered={hovered} clickIn={clickIn} hoverIn={hoverIn} url="visit-production/2"><path d="M93.4465 60.0064C91.7056 60.0064 90.2943 61.4177 90.2943 63.1587C90.2943 64.8996 91.7056 66.311 93.4465 66.311C95.1875 66.311 96.5988 64.8996 96.5988 63.1587C96.5988 61.4177 95.1875 60.0064 93.4465 60.0064Z" fill="#339966" /></VisitPoint>
            <VisitPoint hovered={hovered} clickIn={clickIn} hoverIn={hoverIn} url="visit-production/1"><path d="M94.0615 23.7408C92.3205 23.7408 90.9092 25.1521 90.9092 26.8931C90.9092 28.634 92.3205 30.0453 94.0615 30.0453C95.8024 30.0453 97.2137 28.634 97.2137 26.8931C97.2137 25.1521 95.8024 23.7408 94.0615 23.7408Z" fill="#339966" /></VisitPoint>
            <path d="M80.3106 119.171H101.209V85.0579H80.3106V119.171Z" fill="white" fillOpacity="0.1" />
            <path d="M80.6609 118.821H100.859V85.4082H80.6609V118.821Z" stroke="#545454" />
            <path d="M103.764 183.382H125.438L125.438 55.9593H164.036V40.1528" stroke="#545454" strokeWidth="7" />
            <path d="M82.4785 183.382H55L55 -26H164.139V10.6104" stroke="#545454" strokeWidth="7" />
        </svg>
    )
}

export const ActMap0 = React.memo(ActMap)
import React from 'react'
import PropTypes from 'prop-types'

const SoundIcon = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8.333" height="15" viewBox="0 0 8.333 15">
      <path id="Path_553" data-name="Path 553" d="M14.332,74.875v6h1.91L22.665,85V70l-6.424,4.875Z" transform="translate(-14.332 -70)" fill={fill}/>
    </svg>
  )
}

SoundIcon.propTypes = {
  fill: PropTypes.string
}

SoundIcon.defaultProps = {
  fill: "#FFF"
}

export {SoundIcon}
import React from 'react'
import PropTypes from 'prop-types'

const Heart = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
      <g id="Group_408" data-name="Group 408" transform="translate(-1639.5 -816.5)">
        <path id="Path_697" data-name="Path 697" d="M26.993,14.9C26.811,18.48,23.225,21.334,20,24c-1.7,1.4-2.795,1.4-4.5,0-3.2-2.624-6.358-5.506-6.5-9.212-.173-4.656,5.451-6.562,8.646-2.763.35.475.35.475.7,0C21.531,8.208,27.235,10.14,26.993,14.9Z" transform="translate(1639.5 816.5)" fill="#c40f3c"/>
        <g id="favs" transform="translate(1640 817)">
          <g id="Group_196" data-name="Group 196" transform="translate(-0.5 -0.5)" opacity="0">
            <rect id="Rectangle_274" data-name="Rectangle 274" width="34" height="34" transform="translate(0.5 0.5)" fill="rgba(0,0,0,0)" stroke="#c40f3c" strokeWidth="1"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

Heart.propTypes = {
  fill: PropTypes.string
}

export { Heart }

import React from 'react'
import PropTypes from 'prop-types'

const PlayIcon = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.125" height="14.716" viewBox="0 0 13.125 14.716">
      <g id="Group_205" data-name="Group 205" transform="translate(-1827.168 -299.642)">
        <path id="Path_235" data-name="Path 235" d="M11.966,250.168a.2.2,0,0,0-.3.174v14.316a.2.2,0,0,0,.3.174l12.725-7.158a.2.2,0,0,0,0-.348Z" transform="translate(1815.5 49.5)" fill={fill}/>
      </g>
    </svg>
  )
}

PlayIcon.propTypes = {
  fill: PropTypes.string
}

PlayIcon.defaultProps = {
  fill: "#FFF"
}

export {PlayIcon}
import React from 'react'

export const EmailIcon = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18">
      <g id="Group_407" data-name="Group 407" transform="translate(-54 -9.5)">
        <path id="Path_698" data-name="Path 698" d="M1789,424h-19v16h19Zm-20-1v18h21V423Z" transform="translate(-1715 -413.5)" fill="#c4c4c4"/>
        <path id="Path_699" data-name="Path 699" d="M1769.36,424.764,1770,424l9.5,8,9.5-8,.64.764-10.14,8.543Z" transform="translate(-1715 -413.5)" fill="#c4c4c4"/>
      </g>
    </svg>
  )
}
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd'
import { store } from '../../effects/store'
import { removeItem as removeFavorite, syncWishlist } from '../../effects/actions'

import {
  Heart as HeartIcon,
  ForwardToSocialNetworkIcon,
  FacebookIcon,
  PinterestIcon,
  TwitterIcon
} from '../icons'

const StyledProduct = styled.div`
  background-color: #F4F4F4;

  .inner-contents {
    padding: 2em 2em 2em 4em;
    display: flex;
    flex-direction: row;
    position: relative;
    box-sizing: content-box;

    &::after {
      content: "";
      display: block;
      border: 1px solid #CCCCCC;
      height:0;
      width: 80%;
      position: absolute;
      bottom: 0;
    }

  .product-cover {
    max-width: 200px;
    padding-right: 1.2em;

    @media (min-width: 768px) {
      max-width: 170px;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  .product-info {
    flex-direction: column;
    position: relative;

    .product-name {
      text-transform: uppercase;
      font: normal normal normal 25px 'Catamaran', sans-serif !important;
      letter-spacing: 0.53px;
      color: #2A2A2A;
      font-size: 32px;
      margin-bottom: 0;

      @media (min-width: 768px) {
        font: normal normal normal 32px 'Didot LT Pro';
      }
    }

    .product-description {
      font: normal normal normal 12px DIN2014;
      letter-spacing: 0.42px;
      color: #C4C4C4;
      text-transform: uppercase;
      margin-bottom: 16px;

      @media (min-width: 768px) {
        font: normal normal normal 14px DIN2014;
      }
    }

    .product-dimensions {
      font: normal normal normal 14px/23px DIN2014;
      letter-spacing: 0.42px;
      color: #2A2A2A;
    }
  }

  .product-actions-list {
    list-style: none;
    margin-left: 30%;
    @media (max-width: 390px) {
      margin-left: 0;
    }
    @media (min-width: 768px) {
      margin-left: 0;
      display: flex;
      height: 36px;
      align-items: center;
    }
  }

  .action-heart{
    border: 1px solid rgba(42,42,42,.1);
  }
  .product-action {
    display: inline-block;
    border-radius: 50%;
    
    a {
      padding: 0;

      @media (min-width: 768px) {
          &:first-child {
          padding-left: 0;
        }
      }
      
    }
  }

  .action-forward-networks-list {
    display: none;
  }

  .action-forward {
    height: 34px;
    position: relative;
    margin-left: 10px;

    > a {
      position: absolute;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border: 1px solid rgba(42, 42, 42, .1);
      transition: all .2s linear;
      border-radius: 50%;
      padding: 0;

      svg {
        opacity: .7;
        transition: all .2s linear;
      }

      &:hover {
       
      }
    }

    a {
      width: 34px;
      height: 34px;
      svg {
        width: 34px;

        &#pinterest {
          width: 20px;
          margin-left: 12px;
        }
      }
    }

    &:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 34px;
      transform: translateY(-50%);
      width: 0; 
      height: 0; 
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid #2A2A2A;
      transition: all .1s linear;
      opacity: 0;
    }

    &:hover {
      .action-forward-networks-list {
        height: 34px;
        margin-left: 50px;
        display: inline-flex;
        align-items: center;
      }

      > a {
        border: 1px solid rgba(42, 42, 42, 1);

        svg {
          opacity: 1;
        }
      }

      &:after {
        opacity: 1;
      }
    }
  }
}
`

const onFavoriteClick = id => {
  store.dispatch(removeFavorite({ id }))
  store.dispatch(syncWishlist())
}

const Product = props => {
  const {
    id,
    name,
    description,
    dimensions,
    image
  } = props

  return (
    <StyledProduct>
      <div className="inner-contents flex-column flex-md-row">
        <div className="product-cover m-auto m-md-0">
          <img src={`${process.env.PUBLIC_URL}/products/${image}`} alt={description}/>
        </div>

        <div className="product-info d-flex">
          <h1 className="product-name order-3 order-md-1">{name.split(' ')[0]}</h1>
          <h2 className="product-description order-2">{description}</h2>
          <h3 className="product-dimensions d-none d-md-block order-md-3">{dimensions}</h3>

          <ul className="product-actions-list d-flex order-1 order-md-4">
            <li className="product-action action-like action-heart">
              <Tooltip placement="top" title="Favorite">
                <a href="#/" role="button" onClick={onFavoriteClick.bind(this, id)}>
                  <HeartIcon fill="#C40F3C"/>
                </a>
              </Tooltip>
            </li>

            <li className="product-action action-forward">
              <Tooltip placement="top" title="Share">
                <a href="#/" role="button">
                  <ForwardToSocialNetworkIcon fill="#0A0909" />
                </a>
              </Tooltip>

              <ul className="action-forward-networks-list">
                <li className="product-action action-like">
                  <a href="#/" role="button">
                    <FontAwesomeIcon icon="mailbox" size={"2x"} color="#C4C4C4" />
                  </a>
                </li>

                <li className="product-action action-like">
                  <a href="#/" role="button">
                    <FacebookIcon fill="#C4C4C4" />
                  </a>
                </li>

                <li className="product-action action-like">
                  <a href="#/" role="button">
                    <TwitterIcon fill="#C4C4C4" />
                  </a>
                </li>

                <li className="product-action action-like">
                  <a href="#/" role="button">
                    <PinterestIcon fill="#C4C4C4" />
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </StyledProduct>
  )
}

Product.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  dimensions: PropTypes.string,
  image: PropTypes.string
}

export default React.memo(Product);